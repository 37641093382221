import Tabs from "./Tabs";

export interface ComponentProps {
  component: string;
  bgSportId: string;
}

function BetGeniusContentWindowMesageProvider(props: ComponentProps): string {
  let message: string = "";
  const isFootball: boolean = props.bgSportId === "football";
  switch (props.component) {
    case Tabs.Match: {
      message = isFootball ? "showLive" : "showCourtView";
      break;
    }

    case Tabs.Stats: {
      message = isFootball ? "showStats" : "showMatchStats";
      break;
    }

    case Tabs.Timeline: {
      if (!isFootball) {
        message = "showSetStats";
      }
      break;
    }
  }
  return message;
}

export default BetGeniusContentWindowMesageProvider;

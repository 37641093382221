import React, {FC, memo} from "react";
import {Route, Switch} from "react-router-dom";
import Betslip from "../pages/Betslip";
import CustomerCard from "../pages/CustomerCard";
import {BetslipResponse, CustomerCardResponse} from "../../../services/infoTerminal/infoNetwork";

const ContentRouter: FC<(BetslipResponse | CustomerCardResponse) & { page: number }> = (props) => {
  return (
    <Switch>
      <Route path="/info-terminal/betslip"><Betslip {...props as BetslipResponse}/></Route>
      <Route path="/info-terminal/customer-card"><CustomerCard page={props.page} {...props as CustomerCardResponse} /></Route>
    </Switch>
  );
};

export default memo(ContentRouter);

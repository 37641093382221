import {
  CustomerCardBetslipsResponse,
  CustomerCardHeader,
  CustomerCardResponse,
} from "./infoNetwork";
import dayjs from "dayjs";

const perPage = window.innerHeight > 1200 ? 15 : 7;
let stack: Array<Array<CustomerCardBetslipsResponse | CustomerCardHeader>>;
let interval: ReturnType<typeof setInterval>;

const infoTerminalService = {
  addHeader(betslips: CustomerCardResponse["betslips"]): CustomerCardResponse["betslips"] {
    let lastHeader = "";
    let count = 0;
    const arr = [];
    for(const item of betslips) {
      const d = dayjs(item.date).format("YYYY-MM-DD");
      item.type = "row";
      if(lastHeader === d) {
        arr.push(item);
      } else {
        lastHeader = dayjs(item.date).format("YYYY-MM-DD");
        count++;
        arr.push({  date: lastHeader , type: "header", id: count} as CustomerCardHeader);
        arr.push(item);
      }
    }
    return arr;
  },
  paginate(betslips: CustomerCardResponse["betslips"]): Array<Array<CustomerCardBetslipsResponse | CustomerCardHeader>> {
    const processed = this.addHeader(betslips);
    const arr = [];
    while(processed.length) {
      arr.push(processed.splice(0, perPage));
    }
    return arr;
  },
  consume(betslips: CustomerCardResponse["betslips"]): Array<Array<CustomerCardBetslipsResponse | CustomerCardHeader>> {
    const arr = this.paginate(betslips);
    for(let i = 0; i < arr.length; i++) {
      const a = arr[i];
      // first element should always be header
      const fe = a[0];
      if(fe.type !== "header") {
        a.unshift({ date: dayjs(fe.date).format("YYYY-MM-DD"), id: Number(Math.random().toFixed(2))*100, type: "header" });
      }
      // last item should never be a header
      const le = a[a.length - 1];
      if(le.type === "header") {
        a.pop();
      }
    }
    stack = arr;
    return arr;
  },
  getTotalPages() {
    if(!stack) {
      interval = setInterval(() => {
        return stack?.length - 1;
      }, 1000)
    } else {
      clearInterval(interval);
      return stack?.length - 1;
    }

  }
}

export default infoTerminalService;
import React, { FC } from 'react';
import classNames from "classnames";

import dynamicSettings from "services/dynamicSettings";
import config from "services/configuration";
import { translationsKey, classes } from "../../EventRow/EventRow";

const lang = config.getLanguage();

interface SectionsTimeProps {
    time: string[];
}

const SectionsTime: FC<SectionsTimeProps> = ({time}) => {
    const t = dynamicSettings.getTranslation(translationsKey);
    const sectionInfo = `${time[0]}${t(time[1])}`;
    const classesWrapper = classNames({
        [classes.basicFlex]: true,
        [classes.lwkEventsCol]: true,
        [classes.vertFlex]: true,
        [classes.borderLeft]: true
    });
    const classesTop = classNames({
        [classes.yellowClass]: true,
        [classes.font.fontSections]: true,
        [classes.font.size28]: true,
       [`-f-sections-sm-${lang}`]: !time[2] && sectionInfo.length > 9
    });
    const classesBottom = classNames({
        [classes.bg.custom]: true,
        [classes.bg.grey45]: true,
        [classes.font.fontSections]: true
    })

    return (
        <div className={classesWrapper}>
            <div className={classesTop}>
                {time[2] || ''}
            </div>
            <div className={classesBottom}>
                {sectionInfo}
            </div>
        </div>
    );
}

export default SectionsTime;

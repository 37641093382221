import React, {FC, ReactElement} from "react";
import classNames from "classnames";

import config from "services/configuration";
import dynamicSettings from "services/dynamicSettings";
import { LWKHeaderProps } from "../../types";
import FlipClock from "modules/common/FlipClock";

import "./PageTitle.scss";

const translationsKey = "infoscreens-lwk-headers";

interface TitleComponentInterface {
  titleCssName: string;
  title: string;
}

const PageTitle: FC<LWKHeaderProps> = (props: LWKHeaderProps) => {
  const t = dynamicSettings.getTranslation(translationsKey);
  const page = config.getPage();

  const { title, sport, theme, warning, country } = props;

  const cssIconClasses: Record<string, boolean> = {
    "title__header--content-icon": !props.sport,
    "-icon-league": !!props.sport,
    "-icon-flag": !!country
  };
  const titleCssName = title.toLowerCase().replace(/[^a-z0-9\-_]+/g, "-")

  cssIconClasses[
    country ? `-${country}-flag` : `-icon-${titleCssName}`
  ] = !!sport;
  const cssHeaderClasses: Record<string, boolean> = {
      "basic-flex": true,
      "title__header--content": true,
      "-txLeft-100": warning,
    [titleCssName]: true
  }

  const getTitle: FC<TitleComponentInterface> = ({titleCssName, title}): ReactElement => {
    if(titleCssName.includes("bundesliga")) return <div className="title__header--bundesliga"/>;
    if(titleCssName.includes("turkish")) return <div className={`title__header--turkish_league ${warning && "title__header--content-text-warning"}`}>{t(title)}<div className="image__container" /> </div>;
    return <div className={`title__header--content-text ${warning && "title__header--content-text-warning"}`}>{t(title)}</div>
  }

  return (
    <div className={`basic-flex title title--theme-${theme} events--page-title_available-${props.eventsAvailable}`}>
      <div className="basic-flex title__icon">
        <div className="title__icon--content" />
      </div>
      <div className="basic-flex title__header">
        <div
          className={classNames(cssHeaderClasses)}
        >
          <div className={classNames(cssIconClasses)} />
          <div className="title__header--content-text">
              {getTitle({titleCssName, title})}
          </div>
        </div>
      </div>
      {
          !warning && (
              <div className="title__page">
                {!titleCssName.includes("match_ticker") && <div className="title__page-num">{page}</div>}
                  <div className="title__clock">
                      <FlipClock isMatchTicker={titleCssName.includes("match_ticker")} />
                  </div>
              </div>)
      }
    </div>
  );
};

export default PageTitle;

import React, { FC } from 'react';
import classNames from "classnames";

import dynamicSettings from "services/dynamicSettings";
import config from "services/configuration";
import { translationsKey, classes } from "../../EventRow/EventRow";

const lang = config.getLanguage();

interface PauseTimeProps {
  state: "beforeET" | "betweenET" | "beforePenalty";
}

interface textType {
  beforeET: [string, string]
  betweenET: [string, string]
  beforePenalty: [string, string]
}

const Pause: FC<PauseTimeProps> = ({state}) => {

  const typeText: textType = {
    beforeET: ["PAUSE", "Verl."],
    betweenET: ["HZ", "Verl."],
    beforePenalty: ["PAUSE", "11m"],
  };

  const classesWrapper = classNames({
    [classes.basicFlex]: true,
    [classes.lwkEventsCol]: true,
    [classes.vertFlex]: true,
    [classes.borderLeft]: true
  });
  const classesTop = classNames({
    [classes.yellowClass]: true,
    [classes.font.fontSections]: true,
    [classes.font.size28]: true,
    [`-f-sections-sm-${lang}`]: true
  });
  const classesBottom = classNames({
    [classes.bg.custom]: true,
    [classes.bg.grey45]: true,
    [classes.font.fontSections]: true
  })

  return (
    <div className={classesWrapper}>
      <div className={classesTop}>
        {typeText[state][0]}
      </div>
      <div className={classesBottom}>
        {typeText[state][1]}
      </div>
    </div>
  );
}

export default Pause;

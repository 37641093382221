import React, { FC } from 'react';
import classNames from "classnames";

import dynamicSettings from "services/dynamicSettings";
import { translationsKey, classes } from "../../EventRow/EventRow";

interface NotRunningProps {
    state: string;
}

const NotRunning: FC<NotRunningProps> = ({state}) => {
    const t = dynamicSettings.getTranslation(translationsKey);
    const icon = /^-icon/.test(state);

    const classesWrapper = classNames({
        [classes.lwkEventsCol]: true,
        [classes.borderLeft]: true
    });

    return (
        <div className={classesWrapper}>
            {
                icon
                    ? <span className={state} />
                    : <span className={`${classes.yellowClass}`}>{t(state)}</span>
            }
        </div>
    );
}

export default NotRunning;

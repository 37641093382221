import React from "react";
import "../Title/Title.scss";
import GenericBetGeniusIframeComponent from ".././ScoreCenter/BetGeniusStats/tabs/GenericBetGeniusIframeComponent";
import Tabs from ".././ScoreCenter/BetGeniusStats/tabs/Tabs";
import ScoreBoard from "../ScoreBoard/ScoreBoard";
import StatisticsHeader from "../IframeHeader/StatisticsHeader";
import IframeHeader from "../IframeHeader/IframeHeader";
import BetGeniusTimeline from "../ScoreCenter/BetGeniusTimeline/BetGeniusTimeline";
import { SportEvent as SoccerSportEvent } from "../ScoreCenter/Soccer/SoccerLive/model";
import { SportEvent as TennisSportEvent } from "../ScoreCenter/Tennis/TennisLive/model";

interface Props {
  scoreCenterData?: SoccerSportEvent | TennisSportEvent;
  language: string;
  className?: string;
  sportId?: string;
}

function PitchAnimationBox(props: Props) {
  const bgSportId: string = props.sportId === "soccer" ? "football" : "tennis";
  return props.scoreCenterData && props.scoreCenterData.betGeniusId ? (
    <div className={props.className}>
      <ScoreBoard scoreCenterData={props.scoreCenterData} />
      <GenericBetGeniusIframeComponent
        languageCulture={props.language}
        betGeniusId={props.scoreCenterData.betGeniusId}
        bgSportId={bgSportId}
        component={Tabs.Match}
      />
      <StatisticsHeader />
      <GenericBetGeniusIframeComponent
        languageCulture={props.language}
        betGeniusId={props.scoreCenterData.betGeniusId}
        bgSportId={bgSportId}
        component={Tabs.Stats}
      />
      <IframeHeader scoreCenterData={props.scoreCenterData} />
      <BetGeniusTimeline
        bgSportId={bgSportId}
        language={props.language}
        sportEvent={props.scoreCenterData}
      />
    </div>
  ) : (
    <div />
  );
}

export default PitchAnimationBox;

import config from "../config";

const regexQueryStringKeys = /[^&?=\s]+=/g;

const URL_PARSE = new window.URL(window.location.href);
const queryString = decodeURIComponent(URL_PARSE.search).replace(
    regexQueryStringKeys,
    match => match.toLowerCase()
);
const URL_PARAMS = new URLSearchParams(queryString);

class Configuration {
  getAcceptChannelId() {
    return URL_PARAMS.get("acceptchannelid");
  }

  getBaseView() {
    const rx = new RegExp('^/([^/]+)(.*)$');
    return window.location.pathname.replace(rx, "$1");
  }

  getLeagueId(): string {
    const leaguePathRx = new RegExp('^/league/([0-9]+)');
    if (leaguePathRx.test(URL_PARSE.pathname)) {
      return URL_PARSE.pathname.replace(leaguePathRx, "$1");
    }
    return "";
  }

  getLeagueScreenName(): string {
    const view = this.getBaseView();
    return (view === "league" && URL_PARAMS.get("screen-name")) || (view === "match-ticker" && view) || "";
  }

  isLeagueSpecialLayout(): boolean {
    const leaguesScreenName = this.getLeagueScreenName().toLowerCase();
    return config.views.leagues.leagues_special_layout.indexOf(leaguesScreenName) > -1;
  }

  getViewConfig(): Record<string, string> {
    const leagueName = this.getLeagueScreenName();
    return (
      config.views.leagues[leagueName.toLowerCase()] || config.views.others[leagueName.toLowerCase()]
      || config.views.default
    );
  }

  getLanguage(): string {
    return URL_PARAMS.get("language") || config.default.language;
  }

  getPage(): string {
    return URL_PARAMS.get("page") || "1";
  }

  getEnvironment(): string {
    return URL_PARAMS.get("env") || "sameorigin";
  }

  getAnimationInterval(): number {
    return config.default.animationDurationMs;
  }
  
  useMockData(): boolean {
    return URL_PARAMS.get("env") === "mock";
  }

  getNoFormat() {
    return URL_PARAMS.get("noformat") === "dec"
        ? "dec"
        : "hex";
  }

  getErrorInterval() {
    const errorIntervalS = URL_PARAMS.get("errorinterval");
    return errorIntervalS ? Number(errorIntervalS) * 1000 : 0;
  }

  getEndpoint(endpoint: string): string {
    const env = this.getEnvironment();
    const base = config.endpoints[env].base;
    return base + config.endpoints[env][endpoint];
  }

  getTranslations() {
    return config.translations;
  }

  getTranslationsModule(key: string) {
    return config.translations[key];
  }

  getTeamNamesLength(): number {
    return config.default.teamNamesLength;
  }

  getDefaultScoreColMinWidth(): number {
    return config.default.scoreColMinWidth;
  }

  getSectionsScoreColMinWidth(): number {
    return config.default.scoreSectionsColMinWidth;
  }

  getPullingInterval() {
    return config.network.pullingIntervalMs;
  }

  getHighlightsPullingInterval() {
    return config.network.pullingIntervalHighlightsMs;
  }

  getLicenseTtlDefault() {
    return config.network.licenseTtlSec;
  }

  getUpdateLicenseName() {
    return 'update-license';
  }

  getErrorLicenseName() {
    return 'error-license';
  }

  getPendingLicenseName() {
    return 'pending-license';
  }

  getEventsWaitOnError() {
    return config.network.eventsWaitOnErrorSec;
  }

  getTimesRetryOnError() {
    return config.network.retryTimes;
  }

  getRequestTimeout() {
    return config.network.timeoutMs;
  }

  getScoreShowInterval() {
    return config.default.scoreShowIntervalMs;
  }

  getOddMovementUpInterval() {
    return config.default.oddMovementUpIntervalMs;
  }

  getOddMovementDownInterval() {
    return config.default.oddMovementDownIntervalMs;
  }

  getTotalBetMarketsOnPage() {
    return config.default.totalBetMarketsOnPage;
  }

  getAcceptableEventPoints() {
    return config.default.acceptableEventPoints;
  }

  getFootballTypes() {
    return config.default.footballTypes;
  }

  getSpecificSportBetmarketNames(origName: string) {
    return config.specificSportBetmarketNames[origName];
  }

  getStandardSectionDuration(sport: string) {
    return config.default.standardSectionDuration[sport];
  }

  getSpecialTimeDataKeys() {
    return config.specialDataKeysBySport.time;
  }

  getSpecialSectionsScoreDataKeys() {
    return config.specialDataKeysBySport.score.sections;
  }

  getSpecialAggregateScoreDataKeys() {
    return config.specialDataKeysBySport.score.aggregate;
  }

  getNetwork() {
    return config.network;
  }
}

export default new Configuration();

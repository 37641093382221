import React, { ReactElement } from "react";

import dynamicSettings from "services/dynamicSettings";
import ContentPlaceholder from "../../InfoScreens/components/ContentPlaceholder";

const translationsKey = "errors";
const errorsWithTranslation = ["network-error", "event-unavailable"];

const ErrorPage = ({ error }: { error: Error }): ReactElement => {
  if (!error.message) {
    return <></>;
  }

  const t = dynamicSettings.getTranslation(translationsKey);

  let errorMessageKey = error.message
    .toLowerCase()
    .replace(/[^a-z0-9-]+/, "-");
  if (!errorsWithTranslation.includes(errorMessageKey)) {
    errorMessageKey = "generic-info";
  }

  return (
    <div className="report-wrapper">
      <div className="tipico-logo" />
      <div className="report__content" id="error-page-content">
        <div className="report__content-logo"/>
        <div className="report__content-info">
          <div>{t(errorMessageKey)}</div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

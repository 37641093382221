import React, { FC } from "react";
import classNames from "classnames";

import { EventInfoProps } from "../../types";

export const Generic: FC<Partial<EventInfoProps>> = props => {
  const {
    id,
    teams,
    flex,
    vertflex,
    border,
    leftIco,
    content,
    rightIco,
    classes = [],
    style,
    content2,
    classesContent2 = [],
    nobody
  } = props;
  const attr = (id as string) ? { "data-id": id } : {};
  const classesBlock = {
      "basic-flex": flex,
      "lwk-events-col": !teams,
      events__team: teams,
      "-vertflex": vertflex,
      [border as string]: border
  };
  const clsContent1 = {
      ...classes.reduce((acc: Record<string, boolean>, v: string) => {
          acc[v] = true;
          return acc;
      }, {})
  };
  const clsContent2 = {
      ...classesContent2.reduce((acc: Record<string, boolean>, v: string) => {
          acc[v] = true;
          return acc;
      }, {})
  };

  return (
    <div
      className={classNames(classesBlock)}
      style={style}
    >
      {!nobody && (
        <>
          {leftIco}
          <span
            className={classNames(clsContent1)}
            {...attr}
          >
            {content}
          </span>
          {rightIco}
          {vertflex && (
            <span
              className={classNames(clsContent2)}
            >
              {content2}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default Generic;

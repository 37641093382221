import React, { FC } from 'react';
import classNames from "classnames";

import dynamicSettings from "services/dynamicSettings";
import { translationsKey, classes } from "../../EventRow/EventRow";

interface ProgressTimeProps {
    time: string[];
}

const ProgressTime: FC<ProgressTimeProps> = ({time}) => {
    const t = dynamicSettings.getTranslation(translationsKey);
    const classesWrapper = classNames({
        [classes.basicFlex]: true,
        [classes.lwkEventsCol]: true,
        [classes.vertFlex]: true,
        [classes.borderLeft]: true
    });

    return (
        <div className={classesWrapper}>
            <div className={classes.vertSplitClass}>
                <div className={classes.progressWrapper}>
                    <div className={classes.progressIndicator} style={{width: `${time[0]}%`}} />
                </div>
            </div>
            <div className={`${classes.vertSplitClass} ${classes.bg.custom} ${classes.bg.grey45} ${classes.font.size21}`}>
                {time[1]}{t(time[2])}
            </div>
        </div>
    );
}

export default ProgressTime;

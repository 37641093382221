import config from "../configuration";
import {
    competitorType,
    NormalizedEvents,
    oddType
} from "modules/InfoScreens/types";
import dynamicSettings from "../dynamicSettings";

type ScoreOddRecord = Record<string, number>;
type EntryPayload = {
    score: ScoreOddRecord,
    odds: ScoreOddRecord
}
export type Entry = Record<string, EntryPayload>;
type Stack = Entry[];
type putEventFunk = (eventId: string, score: ScoreOddRecord, odds: ScoreOddRecord) => Stack;
type getEventFunk = (eventId: string) => EntryPayload[];
type shiftRightFunk = () => Stack;

type oddMethodFunk = (odd: oddMoveType) => string;

interface OddMovementMethods {
    start: oddMethodFunk;
    glow: oddMethodFunk;
    active: oddMethodFunk;
    finish: oddMethodFunk;
}

type oddMoveType = {
    id: string;
    value: number
};

/** @function
 * @name stack - keep track on latest incoming:
 * event score, event odds
 */
const stack = () => {
    /** @var {number} limit
     *  keep track on latest results UI receives in {limit} pull intervals
     */
    const limit = Math.ceil(10000 / config.getPullingInterval());

    /**
     * adopted sort-of stack
     */
    let stack: Stack = new Array(limit).fill({});

    /** @function
     * @name shiftRight - insert empty Entries holder, cut the tail
     * @return stack {Stack} events stack
     */
    const shiftRight: shiftRightFunk = (): Stack => {
        stack = [{}, ...stack];
        stack.length = limit;

        return stack;
    }

    /** @function
     * @name putEvent - insert event data in first element of the stack
     * @param eventId {string}
     * @param score {ScoreOddRecord} - score of first and second competitors
     * @param odds {Record<string, number>}
     * @return entries {Stack} modified stack
     */
    const putEvent: putEventFunk = (eventId, score, odds): Stack => {
        stack[0][eventId] = {
            score,
            odds
        };

        return stack;
    }

    /** @function
     * @name getEvent - get all entries that describe a particular event
     * @param eventId {string} an event id
     * @return entries {Stack} all event {eventId} stored in stack
     */
    const getEvent: getEventFunk = (eventId: string): EntryPayload[] => {
        return stack.reduce((acc: EntryPayload[], events: Entry) => {
            return events[eventId]
                ? [...acc, events[eventId]]
                : acc;
        }, []);
    }

    return {
        shiftRight,
        putEvent,
        getEvent
    }
}

/** @function
 * @name stackAccordion operations on stack
 */
const stackAccordion = () => {
    /** @var scoreSize number of pulls to calc scores movements */
    const scoreSize = Math.ceil(config.getScoreShowInterval() / config.getPullingInterval());
    /** @var upSize number of pulls to calc odds up movement */
    const upSize = Math.ceil(config.getOddMovementUpInterval() / config.getPullingInterval());
    /** @var downSize number of pulls to calc odds down movement */
    const downSize = Math.ceil(config.getOddMovementDownInterval() / config.getPullingInterval());
    /** @var stackIns the stack instance */
    let stackIns = stack();
    /** @var stages an odd movement stages */
    const stages = ['start', 'glow', 'active', 'finish'];

    /** event scope vars */
    let eventId = '';
    let sport = '';
    let eventStack: EntryPayload[] = [];
    let scoreChunk: EntryPayload[] = [];
    let upChunk: EntryPayload[] = [];
    let downChunk: EntryPayload[] = [];
    let eventOdds: Record<string, number> = {};
    let eventScore: Record<string, number> = {};

    /** @function
     * @name shiftStack
     */
    const shiftStack = () => {
        stackIns.shiftRight();
    }

    /** @function
     * @name initEvent initialise event
     * @param eventSport {string} name of a sport of an event
     * @param eId {string} event ID
     */
    const initEvent = (eventSport: string, eId: string) => {
        eventId = eId;
        sport = eventSport;
        eventStack = stackIns.getEvent(eId);
        scoreChunk = eventStack.slice(0, scoreSize);
        upChunk = eventStack.slice(0, upSize);
        downChunk = eventStack.slice(0, downSize);
        eventOdds = {};
        eventScore = {};
    }

    /** @function
     * @name saveOddMove saves odd movement in dynamic settings and local var
     * @param odd {oddType} a single odd of an event bet market
     * @return {oddType}
     */
    const saveOddMove = (odd: oddType) => {
        const oddMove = findOddMovement(odd);
        if (oddMove) {
            dynamicSettings.setEventValueMovement(`${odd.id}`, oddMove);
        }

        eventOdds[odd.id] = Number(odd.value);

        return odd;
    }

    /** @function
     * @name saveScoreMove saves score movement in dynamic settings and local var
     * @param competitor {competitorType} a competitor of an event
     * @return {competitorType}
     */
    const saveScoreMove = (competitor: competitorType) => {
        if (sport === 'football') {
            const scoreMove = findScoreMovement(competitor);
            scoreMove
                && dynamicSettings.setEventValueMovement(`${competitor.id}`, scoreMove);
        }

        return competitor;
    }

    /** @function
     * @name findScoreMovement finds score movement
     * @param competitor {competitorType} a competitor of a an event
     * @return {string}
     */
    const findScoreMovement = (competitor: competitorType) => {
        let scoreMove = '';
        if (scoreChunk.find(prev => prev.score[competitor.id] < competitor.score)) {
            scoreMove = 'blink';
        }

        eventScore[competitor.id] = competitor.score;

        return scoreMove;
    }

    /** @function
     * @name findOddMovement finds odd movement in dynamic settings and local var
     * @param odd {oddType} a single odd of an event bet market
     * @return {string}
     */
    const findOddMovement = (odd: oddType): string => {

        return (
            !eventStack[0]?.odds[odd.id] && eventStack[0]?.odds[odd.id] === 0
        )
            ? ''
            : findOddMovementStage(odd);
    }

    const findOddMovementStage = (odd: oddType, stageNum = 0): string => {
        const currentStage = stages[stageNum] as keyof OddMovementMethods;

        const oddCalc = { id: odd.id, value: Number(odd.value) }
        const oddMove = checkOddMovement[currentStage].call(this, oddCalc);

        return (oddMove || stageNum === stages.length - 1)
            ? oddMove
            : findOddMovementStage(odd, ++stageNum);
    }

    const checkOddMovement = {
        start: (odd: oddMoveType) => eventStack[0]?.odds[odd.id] && eventStack[0]?.odds[odd.id] < odd.value
            ? 'up-start'
            : eventStack[0]?.odds[odd.id] > odd.value
                ? 'down-start'
                : '',

        glow: (odd: oddMoveType) => (
            eventStack[0]?.odds[odd.id] === odd.value
            && eventStack[1]?.odds[odd.id]
            && eventStack[1]?.odds[odd.id] < odd.value
        )
            ? 'up-glow'
            : '',

        active: (odd: oddMoveType) => (
            eventStack[0]?.odds[odd.id] === odd.value
            && eventStack[1]?.odds[odd.id] > odd.value
        )
            ? 'down-active'
            : eventStack.slice(0, 2).find(event => !event.odds[odd.id] || event.odds[odd.id] === 0)
                ? ''
                : upChunk.slice(2).find(event => event.odds[odd.id] > 0 && event.odds[odd.id] < odd.value)
                    ? 'up-active'
                    : downChunk.slice(2).find(event => event.odds[odd.id] > odd.value)
                        ? 'down-active'
                        : '',

        finish: (odd: oddMoveType) => {
            if (eventStack.slice(0, 2).find(event => !event.odds[odd.id] || event.odds[odd.id] === 0)) {
                return '';
            }

            return (
                // upChunk.every(event => event.odds[id] === value) &&
                eventStack[upSize]?.odds[odd.id] > 0
                && eventStack[upSize]?.odds[odd.id] < odd.value
            )
                ? 'up-finish'
                : eventStack[downSize]?.odds[odd.id] > odd.value
                    ? 'down-finish'
                    : '';
        }
    }

    /** @function
     * @name addToStack
     * @param event {NormalizedEvents}
     * @return event {NormalizedEvents}
     */
    const addToStack = (event: NormalizedEvents): NormalizedEvents => {
        stackIns.putEvent(eventId, eventScore, eventOdds);

        return event;
    }

    const reInit = () => {
        stackIns = stack();
    }

    const _testStack = () => eventStack;

    return {
        shiftStack,
        initEvent,
        saveScoreMove,
        saveOddMove,
        addToStack,
        reInit,
        _testStack
    }
}

export default stackAccordion();

import React, {FC, ReactElement, useEffect, useState} from "react";

import dynamicSettings from "services/dynamicSettings";
import loadTranslation from "services/translation";

const InfoTerminalTranslations: FC<{ children: ReactElement }> = (props: {children: ReactElement}) => {
  const [translationReady, setTranslationReady] = useState(false);

  useEffect(() => {
    if (!dynamicSettings.getTranslation("infoterminal")) {
      loadTranslation("infoterminal")
        .then(() => {
          setTranslationReady(true);
        })
        .catch(e => {
          throw e;
        });
    } else {
      setTranslationReady(true);
    }
  }, []);

  if(!translationReady) {
    return <></>
  }

  return props.children
}

export default InfoTerminalTranslations;

import {LicenseDataType, PullMTEventsCB} from "../../InfoScreens/types";
import {useEffect} from "react";
import pickupLineMT from "../../../services/pickupLineMT";
import hightlightsService from "../../../services/highlights";
import dynamicSettings from "../../../services/dynamicSettings";
import {AxiosResponse} from "axios";
import eventsNetwork from "../../../services/events/eventsNetwork";
import config from "../../../services/configuration";
import loadTranslation from "../../../services/translation";
import {resetServicesOnError} from "./useEvents";
import highlightsNetwork from "../../../services/highlights/highlightsNetwork";

const pullInterval = config.getHighlightsPullingInterval();
const updateLicense = config.getUpdateLicenseName();
const errorLicense = config.getErrorLicenseName();
const pendingLicense = config.getPendingLicenseName();
const noop = () => {};

const useHighlights = (cb: PullMTEventsCB) => {
  useEffect(() => {
    const idEventsInterval = window.setInterval(() => {
      const eventsRaw = pickupLineMT.consume();
      if (eventsRaw) {
        hightlightsService.refresh();
        const eventsUI = hightlightsService.normalizer(eventsRaw.highlights);
        cb(new Error(), { highlights: eventsUI, error: new Error(), eventsReady: true });
      }
    }, 500)
    return () => window.clearInterval(idEventsInterval);
  }, []);

  useEffect(() => {
    // call for license
    // 200 => set license expire t/o
    async function getLicense(): Promise<void> {
      try {
        dynamicSettings.setLicense(pendingLicense);
        // retries are happening here
        const licenseRes: AxiosResponse<LicenseDataType> = await eventsNetwork.retrieveLicenseId();
        dynamicSettings.setLicense(licenseRes.data.licenseKey);
        eventsNetwork.licenseUpdate(licenseRes.data.ttlSeconds);
      } catch (e: any) {
        // error => refresh stack,
        // invalidate events,
        // set license value to 'update-license'
        cb(e);
        resetServicesOnError();
        dynamicSettings.setLicense(errorLicense);
        eventsNetwork.licenseUpdate(config.getLicenseTtlDefault());
      }
    }

    // get initial license onload
    setTimeout(async () => await getLicense(), 0);

    // update license ID
    const idLicenseInterval = window.setInterval(
      async () => {
        const license = dynamicSettings.getLicense();
        if (license === updateLicense) {
          await getLicense();
        }
      },
      500
    );

    return () => window.clearInterval(idLicenseInterval);
  }, []);

  useEffect(() => {
    async function getHighlights() {
      try {
        dynamicSettings.setTransportStatus("busy");
        const highlightsRes = await highlightsNetwork.retrieveHighlights();
        pickupLineMT.rollout(highlightsRes);
        dynamicSettings.setTransportStatus("ready");
      } catch (e: any) {
        cb(e);
        resetServicesOnError();
        setTimeout(() => {
          dynamicSettings.setTransportStatus("ready");
        }, config.getEventsWaitOnError() * 1000);
      }
    }

    loadTranslation("infoscreens").then(() => {
      cb(new Error(),{ translationsReady: true });
    });
    setTimeout(() => {
      const status = dynamicSettings.getTransportStatus();
      const validLicense = ![updateLicense, errorLicense, pendingLicense].includes(dynamicSettings.getLicense());
      if (status === 'ready' && validLicense) {
        getHighlights().then(noop);
      }
    }, 500);

    const idEventsInterval = window.setInterval(
      () => {
        const status = dynamicSettings.getTransportStatus();
        const validLicense = ![updateLicense, errorLicense, pendingLicense].includes(dynamicSettings.getLicense());
        if (status === 'ready' && validLicense) {
          getHighlights().then(noop);
        }
      },
      pullInterval
    );

    return () => window.clearInterval(idEventsInterval);
  }, []);
}

export default useHighlights;
import React, {FC} from 'react';
import "./globalloader.scss";
import dynamicSettings from "../../../services/dynamicSettings";
const translationKey = "infoterminal";

const GlobalLoader: FC<{show: boolean}> = ({show}) => {

  const t = dynamicSettings.getTranslation(translationKey);

  return show ? (
    <div className="app__loader___container">
      <div className="app__loader___wrapper">
        <img className="app__loader___icon" alt="icon"/>
        <p>{t("wait-msg")}...</p>
      </div>
    </div>
  ) : <span />
};

export default GlobalLoader;
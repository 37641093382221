import React, { FC } from "react";
import classNames from "classnames";

import { HeaderRowInfoProps } from "../../types";
import dynamicSettings from "services/dynamicSettings";

const translationsKey = "infoscreens-lwk-headers";

const HeaderRowInfo: FC<HeaderRowInfoProps> = props => {
  const { content, classes = [], ico } = props;
  const t = dynamicSettings.getTranslation(translationsKey);
  const header = t(content);
  const combineClasses = {
    "lwk-events-col": true,
    ...classes.reduce((acc: Record<string, boolean>, v: string) => {
      acc[v] = true;
      return acc;
    }, {})
  };

  return (
    <div className={classNames(combineClasses)}>
      {ico && <span className={`-icon-${content}`} />}
      <span>{header}</span>
    </div>
  );
};

export default HeaderRowInfo;

import React, { Component, ReactElement } from "react";

import ErrorPage from "./ErrorPage";
import dynamicSettings from "services/dynamicSettings";
import loadTranslation from "services/translation";

export class ErrorBoundaries extends Component<
  { children: ReactElement; errorHandler: string },
  { error?: Error; translationsReady: boolean; }
> {

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  state = {
    translationsReady: false,
    error: new Error()
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log("error: ", error, errorInfo);
  }

  componentDidMount() {
    if (!dynamicSettings.getTranslation("errors")) {
      loadTranslation("error")
        .then(() => {
          this.setState({
            translationsReady: true
          });
        })
        .catch(e => {
          this.setState({
            error: e
          });
        });
    } else {
      this.setState({
        translationsReady: true
      });
    }
  }

  render() {
    if (!this.state.translationsReady) {
      return <></>;
    }
    if (this.state.error.message) {
      switch (this.props.errorHandler) {
        case "page":
        case "dialog":
          return <ErrorPage {...this.state} />;
        default:
          console.log("Error handler not found");
          return <>{this.state.error.message}</>;
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundaries;

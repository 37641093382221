import React, { FC, ReactElement } from "react";
import { Helmet } from "react-helmet";

import PageTitle from "../components/PageTitle/PageTitle";
import HeaderRow from "../components/Header/HeaderRow";
import EventRow from "../components/EventRow/EventRow";
import WarningContent from "modules/common/ErrorReporting/WarningContent";
import config from "services/configuration";
import { BetMarketHeader, InfoScreenPage, NormalizedEvents } from "../types";
import "../../../../res/styles/infoscreens/basic.scss";
import dynamicSettings from "services/dynamicSettings";

const translationsKey = "infoscreens-lwk-headers";

const viewConfig = config.getViewConfig();
const { title, sport = "", theme = "red", country} = viewConfig;

const LiveConference: FC<InfoScreenPage> = (props): ReactElement => {
  if (props.error.message) {
    throw props.error;
  }

  const t = dynamicSettings.getTranslation(translationsKey);
  const eventsAvailable = !!props.rows.length;

  return (
    <>
      <Helmet>
        <title>{t(title)} Infoscreen</title>
        <meta name="description" content={`${t(title)} Infoscreen`} />
      </Helmet>
      <PageTitle
        title={title}
        sport={sport}
        theme={theme}
        warning={!eventsAvailable}
        country={country}
        eventsAvailable={eventsAvailable}
      />
      <WarningContent
        infoMain={props.eventsReady && !eventsAvailable ? `events-unavailable` : ""}
        infoSub={
          !eventsAvailable && sport
            ? `events-unavailable-${title}`
            : ""
        }
      />
      {eventsAvailable &&
        props.rows.map((row, idx) => {
          return row.type === "header" ? (
            <HeaderRow
              {...row as BetMarketHeader}
              key={`${(row as BetMarketHeader).sport}${row.type}`}
            />
          ) : (
            <EventRow
              {...row as NormalizedEvents}
              key={`${(row as NormalizedEvents).id}-${idx}`}
            />
          );
        })}
    </>
  );
};

export default LiveConference;

import * as React from "react";
import BetGeniusScorePitch from "../BetGeniusScorePitch";
import { SportEvent as SoccerSportEvent } from "../Soccer/SoccerLive/model";
import { SportEvent as TennisSportEvent } from "../Tennis/TennisLive/model";
import Tabs from "../BetGeniusStats/tabs/Tabs";
import GenericBetGeniusIframeComponent from "../BetGeniusStats/tabs/GenericBetGeniusIframeComponent";

interface Props {
  sportEvent: SoccerSportEvent | TennisSportEvent;
  language: string;
  bgSportId: string;
}

function BetGeniusTimeline(props: Props): JSX.Element | null {
  return props.bgSportId === "football" ? (
    <BetGeniusScorePitch
      data={(props.sportEvent as SoccerSportEvent).event.eventPointList}
    />
  ) : (
    <GenericBetGeniusIframeComponent
      languageCulture={props.language}
      betGeniusId={props.sportEvent.betGeniusId}
      bgSportId={props.bgSportId}
      component={Tabs.Timeline}
    />
  );
}

export default BetGeniusTimeline;

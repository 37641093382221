import React, { FC } from "react";
import classnames from "classnames";

import Time from "../EventRowInfo/Time/Time";
import Id from "../EventRowInfo/Id";
import EventRowOdds from "../EventRowOdds/EventRowOdds";
import EventRowSpecialEvent from "../EventRowSpecialEvent/EventRowSpecialEvent";
import EventRowLiveComment from "../EventRowLiveComment/EventRowLiveComment";
import TeamsScore from "../EventRowInfo/TeamsScore";
import { NormalizedEvents } from "../../types";
import config from "services/configuration";
import "./EventRow.scss";

export const translationsKey = "infoscreens-lwk-event";
export const classes = {
    vertSplitClass: "cell--vertical-half",
    yellowClass: "-color-yellow",
    basicFlex: "basic-flex",
    eventTeams: "events__team",
    lwkEventsCol: "lwk-events-col",
    vertFlex: "-vertflex",
    borderLeft: "-border-left-000-2",
    textOverflow: "-overflow-ellipsis",
    relative: "-relative",
    bg: {
        custom: "-bg-custom",
        grey45: "-bg-grey45"
    },
    font: {
        size21: '-fs-21',
        size28: '-fs-28',
        fontSections: '-f-sections'
    },
    progressWrapper: "progress-bar-wrapper",
    progressIndicator: "progress-indicator",
    sectionNameVert: "section-name-2vert",
    lineHeight60: "-line-height-60",
    score: "score",
    fullTimeScore: "full__time--score"
};

const EventRow: FC<NormalizedEvents> = (props: NormalizedEvents) => {
    const {
        id,
        code,
        sport,
        onTop,
        time,
        competitor1,
        competitor2,
        score,
        odds,
        liveComment,
        specialEvent
    } = props;
    const specialLayout = config.isLeagueSpecialLayout();
    const teamsScoreWrapClasses = classnames({
        "basic-flex": true,
        "lwk-events-col": true,
        "-col-team-score": specialLayout
    });

    return (
        <div className="events__data">
            <div className="basic-flex events__data--row">
                <Id id={id} code={code} onTop={onTop} />
                <Time {...time} />
                <div className={teamsScoreWrapClasses}>
                    <TeamsScore
                        sport={sport}
                        score={score}
                        competitor1={competitor1}
                        competitor2={competitor2}
                    />
                </div>
                <EventRowOdds odds={odds} />
            </div>
            <EventRowLiveComment comment={liveComment} show={!!liveComment} />
            <EventRowSpecialEvent
                specialEvent={specialEvent}
                show={!liveComment && !!specialEvent}
            />
        </div>
    );
};

export default EventRow;

import React, {useState, useCallback, useEffect} from "react";

import LiveConference from "../pages/LiveConference";
import { LeagueRouterComponentProps, LWKContainerState, PullEventsCB } from "../types";
import ErrorBoundaries from "modules/common/ErrorReporting/ErrorBoundaries";
import useMock from "modules/common/hooks/useMock";
import useEvents, {resetServicesOnError} from "modules/common/hooks/useEvents";
import config from "services/configuration";
import {pingGoogle} from "../../../services";
import dynamicSettings from "../../../services/dynamicSettings";

let lastStatePayload: Partial<LWKContainerState> | undefined;
let showingError = false;
const LiveConferenceContainer = (props: LeagueRouterComponentProps & { errorResetKey: number }) => {
    const [state, setState] = useState<LWKContainerState>({
        rows: [],
        translationsReady: false,
        eventsReady: false,
        error: new Error()
    });

    let _isMounted = false;

    useEffect(() => {
        _isMounted = true;
        return () => {
            _isMounted = false;
        }
    }, [])

    const checkNetwork = () => {
        pingGoogle().then(isStable => {
            if(!isStable) {
                showingError = true;
                return setState({
                    ...state,
                    error: new Error('network error')
                });
            } else {
                showingError = true;
                return setState({
                    ...state,
                    error: new Error("404")
                })
            }
        })
    }

    const cb: PullEventsCB = useCallback((error, statePayload?: Partial<LWKContainerState>) => {
        if(statePayload?.rows && statePayload?.rows.length > 0) {
            lastStatePayload = statePayload;
        }
        if (error.message && _isMounted) {
            if(!showingError) {
                // if there is no old value and the error appears, check network and show error right away
                if(!lastStatePayload) {
                    checkNetwork();
                }
                const timeout = setTimeout(() => {
                    if(lastStatePayload && lastStatePayload?.rows) {
                        clearInterval(interval);
                        checkNetwork();
                    }
                }, config.getErrorInterval());
                const interval = setInterval(() => {
                    if(!showingError) {
                        pingGoogle().then(isStable => {
                            if(isStable) {
                                clearTimeout(timeout);
                                clearInterval(interval);
                                resetServicesOnError();
                                dynamicSettings.setTransportStatus("ready");
                                return setState({
                                    ...state,
                                    ...lastStatePayload,
                                    error: new Error()
                                })
                            }
                        })
                    }
                }, 1500);
            }
            return;
        }
        if(_isMounted) {
            showingError = false;
            return setState({
                ...state,
                ...statePayload,
                error: new Error()
            });
        }
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    config.useMockData() ? useMock(cb) : useEvents(cb);

    return (
        <ErrorBoundaries
            errorHandler="page"
            key={state.error.message || "noErrorKey"}
        >
            <LiveConference {...props} {...state} />
        </ErrorBoundaries>
    );
}

export default LiveConferenceContainer;

import * as React from "react";
import FootballScoreBoard from "./FootballScoreBoard";
import TennisScoreBoard from "./TennisScoreBoard";
import { SportEvent as SoccerSportEvent } from "../ScoreCenter/Soccer/SoccerLive/model";
import { SportEvent as TennisSportEvent } from "../ScoreCenter/Tennis/TennisLive/model";

interface Props {
  scoreCenterData: SoccerSportEvent | TennisSportEvent;
}

function ScoreBoard({ scoreCenterData }: Props) {
  return scoreCenterData.sportId === "soccer" ? (
    <FootballScoreBoard sportEvent={scoreCenterData as SoccerSportEvent} />
  ) : (
    <TennisScoreBoard sportEvent={scoreCenterData as TennisSportEvent} />
  );
}

export default ScoreBoard;

import React, { FC } from "react";

const HeaderOddNamesExplain: FC<{ odd: string }> = props => {
  const { odd } = props;

  return (
    <div className="odd__title--explain">
      <span>{odd}</span>
    </div>
  );
};

export default HeaderOddNamesExplain;

import PitchAnimationBox from "./PitchAnimationBox";
import * as React from "react";
import { Event as FootballEvent } from "../ScoreCenter/Soccer/SoccerLive/model";
import { Event as TennisEvent } from "../ScoreCenter/Tennis/TennisLive/model";

interface Props {
  sportId?: string;
  language: string;
  className?: string;
}

const INITIAL_STATE: Props = {
  language: "de-de"
};

interface TennisScoreCenterDataProps {
  sportId: string;
  event: TennisEvent;
  isLive: boolean;
  betGeniusId?: string;
}

interface FootballScoreCenterDataProps {
  sportId: string;
  event: FootballEvent;
  isLive: boolean;
  betGeniusId?: string;
}

interface State {
  language: string;
  scoreCenterData?: FootballScoreCenterDataProps | TennisScoreCenterDataProps;
}

export default class PitchAnimationRestService extends React.Component<
  Props,
  State
> {
  private intervalID = 0;
  private _isMounted = false;
  private timerIDForSayImAlive = 0;

  constructor(props: Props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentWillUnmount(): void {
    this._isMounted = false;
    clearInterval(this.intervalID);
    clearInterval(this.timerIDForSayImAlive);
  }

  componentDidMount(): void {
    this.intervalID = window.setInterval(this.callRest, 5000);
    this.timerIDForSayImAlive = window.setInterval(
      () => this.sayImAlive(),
      3000
    );
    this._isMounted = true;
  }
  callRest = () => {
    fetch("https://api.tipico.com/pitch/" + this.props.sportId, {
      method: "GET"
    })
      .then(response => response.json())
      .then(data => {
        this.updateState({
          language: this.props.language,
          scoreCenterData: JSON.parse(data[data.length - 1][1])
        } as State);
      })
      .catch(() => {
        this.resetToInitialState();
      });
  };

  render() {
    return (
      <PitchAnimationBox
        scoreCenterData={this.state.scoreCenterData}
        language={this.props.language}
        className={this.props.className}
        sportId={this.props.sportId}
      />
    );
  }

  private resetToInitialState() {
    this.setState(INITIAL_STATE);
  }

  private updateState(data: State) {
    if (!this._isMounted) {
      return;
    }
    this.setState(data);
  }

  private sayImAlive() {
    if (window.hasOwnProperty("watcher")) {
      (window as any).watcher.screenIsAlive();
    }
  }
}

import React, { FC } from "react";

import { BetMarketHeader, betMarketHeadersType } from "../../types";
import HeaderRowInfo from "./HeaderRowInfo";
import HeaderOddNames from "./HeaderOddNames";
import "./HeaderRow.scss";

const HeaderRow: FC<BetMarketHeader> = (props: BetMarketHeader) => {
  return (
    <>
      <div className="basic-flex events__headers">
        <div className="basic-flex events__headers--titles">
          {[
            {
              content: "nummer"
            },
            {
              content: "time",
              classes: ["-border-left-000-2"]
            },
            {
              content: props.sport,
              classes: [
                "event__header--sport",
                "-border-left-000-2",
                "-text-left"
              ],
              ico: true
            }
          ].map(headerInfoProps => (
            <HeaderRowInfo {...headerInfoProps} key={headerInfoProps.content} />
          ))}
          {props.betMarketHeaders.map((bmHeader: betMarketHeadersType, idx) => (
            
            <HeaderOddNames sport={props.sport} bmHeader={bmHeader} key={bmHeader.id || idx} />
          ))}
        </div>
      </div>
    </>
  );
};

export default HeaderRow;

import React from "react";
import "./IframeHeader.scss";

function StatisticsHeader() {
  return (
    <div className={"iframe-header-box"}>
      <img className="header-icon statistics-icon" />
      <div className={"statistics-text"}>Statistiken</div>
    </div>
  );
}

export default StatisticsHeader;

import React from "react";
import ReactDom from "react-dom";
import App from "./App";
import "./index.scss";

const render = () => ReactDom.render(<App />, document.getElementById("root"));
render();

if ((module as any).hot) {
  (module as any).hot.accept("./App", render);
}

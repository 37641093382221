import * as React from "react";
import classnames from "classnames";
import "./FootballScoreBoard.scss";
import { SportEvent as SoccerSportEvent } from "../ScoreCenter/Soccer/SoccerLive/model";

interface Props {
  sportEvent: SoccerSportEvent;
}

function FootballScoreBoard(props: Props) {
  const event = props.sportEvent.event;

  if (!event) {
    return null;
  }

  return (
    <div className={"football-scorecenter"}>
      <div className={"soccer"}>
        <div className={"row"}>
          <div className={"team"}>{event.team1}</div>
          <div className={"teamScore"}>
            <div className={"square"}>{event.pointScore.count1}</div>
            <div> : </div>
            <div className={"square"}>{event.pointScore.count2}</div>
          </div>
          <div className={"team right"}>{event.team2}</div>
        </div>
        <div className={"row"}>
          <div className={"team"}>
            <span className={"icon redCard"}>{event.redCards.count1}</span>
            <span className={"icon yellowCard"}>
              {event.yellowCards.count1}
            </span>
            <span className={"icon corner"}>{event.cornerKicks.count1}</span>
          </div>
          <div className={"time"}>{event.date}</div>
          <div className={"team right"}>
            <span className={classnames("icon", "redCard")}>
              {event.redCards.count2}
            </span>
            <span className={classnames("icon", "yellowCard")}>
              {event.yellowCards.count2}
            </span>
            <span className={classnames("icon", "corner")}>
              {event.cornerKicks.count2}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FootballScoreBoard;

import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";

import MetaStateCheck from "./modules/common/MetaStateCheck";
import PitchAnimation from "./modules/PitchAnimation/pages/PitchAnimation";
import LiveConferenceContainer from "./modules/InfoScreens/containers/LiveConferenceContainer";
import ErrorBoundaries from "./modules/common/ErrorReporting/ErrorBoundaries";

import "./modules/common/ErrorReporting/ErrorPage.scss";
import MatchTickerContainer from "./modules/InfoScreens/containers/MatchTickerContainer";
import Container from "./modules/InfoTerminal/container";
import InfoTerminal from "./modules/InfoTerminal/pages/InfoTerminal";

const App = () => {
  return (
    <ErrorBoundaries errorHandler="page">
      <MetaStateCheck>
        <Router>
          <Switch>
            <Route path="/" exact component={PitchAnimation} />
            <Route path="/match-ticker" exact component={MatchTickerContainer} />
            <Route
              path="/live-conference"
              exact
              component={LiveConferenceContainer}
            />
            <Route path="/league/:id" component={LiveConferenceContainer} />
            <Route path="/info-terminal-screen-saver" exact component={InfoTerminal} />
            <Route path="/info-terminal" exact component={InfoTerminal} />
            <Route path="/info-terminal/betslip/:betslipId" component={Container} />
            <Route path="/info-terminal/customer-card/:ccId" component={Container} />
            <Redirect to="/" />
          </Switch>
          <div className="report__preload" />
        </Router>
      </MetaStateCheck>
    </ErrorBoundaries>
  );
};

export default App;

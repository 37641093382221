import React, {FC} from "react";
import {CustomerCardHeader} from "../../../../../services/infoTerminal/infoNetwork";
import dayjs from "dayjs";
import dynamicSettings from "../../../../../services/dynamicSettings";

const CustomerCardHeader: FC<CustomerCardHeader> = (props) => {

  const t = dynamicSettings.getTranslation("infoterminal");

  return (
    <div className="customer-card__header___wrapper">
      <span>
        {dayjs(props.date).format("MMM DD, YYYY")}
      </span>
      <span>
        ({t("stake")})
      </span>
      <span>
        ({t("prize")})
      </span>
    </div>
  )
}

export default CustomerCardHeader;
import {MatchTickerHighLight} from "../../modules/InfoScreens/types";

export type Stack = Array<MatchTickerHighLight>;
export type HighlightsUI = Record<number, MatchTickerHighLight[]>;
type putEventFunk = (props: MatchTickerHighLight) => Stack;
type getEventFunk = (props: MatchTickerHighLight) => MatchTickerHighLight | undefined;
type ClearStackFunk = () => Stack;

let stack: Stack = [];
export const stackLimit = 10;
export const childLimit = 5;

const highlightsStack = () => {
  const clear: ClearStackFunk = (): Stack => {
    stack = new Array(stackLimit).fill({});
    return stack;
  }

  const putHighlight: putEventFunk = (props): Stack => {
    stack = [props, ...stack];
    const removed = stack.pop();
    return stack;
  }

  const getHighlight:getEventFunk = (props): MatchTickerHighLight | undefined => {
    return stack.find(e => e.eventId === props.eventId && e.occurredAt === props.occurredAt);
  }

  const returnStack = () => stack;

  return {
    clear,
    putHighlight,
    getHighlight,
    returnStack
  }
}

const hightlightsStackAccordion = () => {

  let stackIns = highlightsStack();

  let eventStack: Stack = [];

  const clearStack = () => {
    stackIns.clear();
  }
  const initHighlights = () => {
    eventStack = stackIns.returnStack()
  }

  const addToStack = (highlight: MatchTickerHighLight): Stack => {
    return stackIns.putHighlight(highlight);
  }

  const reInit = () => {
    stackIns = highlightsStack();
  }

  const all = () => {
    return stackIns.returnStack();
  }
  const _testStack = () => eventStack;

  return {
    clearStack,
    initHighlights,
    reInit,
    addToStack,
    _testStack,
    all
  }
}

export default hightlightsStackAccordion();
import React, {createRef, FC, useEffect} from "react";
import {BetslipResponse} from "../../../services/infoTerminal/infoNetwork";
import BetslipRows from "../components/rows/BetslipRows/BetslipRows";

let height = 0;
let currentHeight = 0;
let bool = true;
let interval: ReturnType<typeof setInterval>;

const Betslip: FC<BetslipResponse> = (props) => {

  const containerRef = createRef<HTMLDivElement>();
  const wrapperRef = createRef<HTMLDivElement>();


  const speed = 60;
  const step = 3;

  const scroll = () => {
    if (currentHeight < -20 || currentHeight > height) bool = !bool;
    if (bool) {
      currentHeight += step;
      scrollSupport();
    } else {
      currentHeight -= step;
      scrollSupport();
    }
  }

  const scrollSupport = () => {
    if(containerRef?.current?.scroll) {
      containerRef?.current?.scroll({top: currentHeight, behavior: "smooth"});
    } else {
      // @ts-ignore
      containerRef?.current?.scrollTop = currentHeight;
    }
  }

  useEffect(() => {
    const crEl = containerRef?.current;
    const el = wrapperRef?.current;
    if(el?.offsetHeight && crEl?.offsetHeight && (el?.offsetHeight > crEl?.offsetHeight)) {
      height = (el?.offsetHeight - crEl.offsetHeight) + 40;
      interval = setInterval(scroll, speed)
    }
    return () => {
      clearInterval(interval)
    }
  }, [wrapperRef]);

  return (
    <div ref={containerRef} className="info-terminal--betslip-row-container">
      <div ref={wrapperRef}>
        {
          props?.bets?.length > 0 ?
            props.bets.map((e, idx) => <BetslipRows key={idx} {...e}  />)
            : <div>No data to show</div>
        }
      </div>
    </div>
  )
}

export default Betslip;
import React, {FC, useEffect, useState} from "react";
import {CustomerCardResponse} from "../../../../services/infoTerminal/infoNetwork";
import infoTerminalService from "../../../../services/infoTerminal";
import dynamicSettings from "../../../../services/dynamicSettings";


const CustomerCardSideBar: FC<CustomerCardResponse & { page: number }> = (props) => {

  const t = dynamicSettings.getTranslation("infoterminal");

  const [pages, setPages] = useState<number | null>();

  useEffect(() => {
    setPages(infoTerminalService.getTotalPages());
  });

  return (
    <div className={"customer-sidebar__wrapper"} id="customer-card-sidebar">
      <div className="customer-sidebar__info-ico" id="customer-card-sidebar-icon" />
      <div className="customer-sidebar__info" id="customer-card-sidebar-info">
        {t("customer-card-sidebar")}
      </div>
      <div id="customer-card-sidebar-pagination" className="customer-sidebar__pagination-text">Seite {props.page+1} von {typeof pages === "number" && pages+1}</div>
    </div>
  )
}

export default CustomerCardSideBar;
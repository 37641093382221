import config from "services/configuration";
import {
  BetMarketEvent,
  GameClockStopWatch,
  GameClockProgress,
  GameClockSections,
  competitorTypeSections, EventTime
} from "modules/InfoScreens/types";
import { calcNonStandardDuration, isNonStandardDuration } from "services/helper";
import stopWatch from './stopWatch';
import progress from './progress';
import sections from "./sections";
import { pad } from '../../helper';

export default {
  /*
Calculate a time for UI
@param {BetMarketEvent} event - a single event
@return {EventType}
*/
  processEventTime(event: BetMarketEvent): EventTime {
    const upcoming = event.liveStatus === "upcoming"
    const time = upcoming
      ? this.upcomingEventTime(event)
      : this.liveEventTime(event);
    const type = upcoming
      ? "upcoming"
      : event.gameClock.clockType;
    const state = (type !== "upcoming" && event.gameClock.unreliable)
      ? 'unreliable'
      : event.gameClock.eventState;
    const techDisplayName = event.gameClock.techDisplayName;
    const sectionType = event.gameClock.sectionType;

    return {
      type,
      state,
      time,
      techDisplayName,
      sectionType
    }
  },

  /*
  An upcoming event time processing
  @param {BetMarketEvent} event
  @returns (string[d|t, t?]) {d} date indicator, {t} time
  */
  upcomingEventTime: (event: BetMarketEvent): string[] => {
    // lang
    const lang = config.getLanguage();

    // date from timestamp
    const d = new Date(event.startTime);
    // date now
    const dNow = new Date();

    // diff in days
    const dUTC = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    const dNowUTC = Date.UTC(
      dNow.getFullYear(),
      dNow.getMonth(),
      dNow.getDate()
    );
    const diffMs = dUTC - dNowUTC;
    const diffDays = Math.floor(Math.abs(diffMs) / 1000 / 60 / 60 / 24);

    const hours = pad.time(d.getHours());
    const mins = pad.time(d.getMinutes());

    // time to display in columns
    const timeFormatted = [`${hours}:${mins}`];

    if (diffMs < 0) { // upcoming with datestamp in past should never happen
      return ["in Past", ...timeFormatted];
    } else if (diffDays <= 0) { // today
      if (isNonStandardDuration(event.sport, event.gameClock.sectionDuration)) {
        timeFormatted[1] = calcNonStandardDuration(event.gameClock.regularSectionCount, event.gameClock.sectionDuration);
      }
      return timeFormatted;
    } else if (diffDays >= 7) { // more than a week ahead
      const weekdayShort = d.toLocaleDateString(lang, {
        weekday: "short"
      });
      const day2Digits = d.toLocaleDateString(lang, {
        day: "2-digit"
      });
      const month2Digits = d.toLocaleDateString(lang, {
        month: "2-digit"
      });
      const dateMoreThanWeekAhead = `${weekdayShort}. ${day2Digits}.${month2Digits}`
        .replace(/[\\.]+/g, ".")
        .replace(/\\.$/, "");

      return [dateMoreThanWeekAhead, ...timeFormatted];
    } else { // within a week ahead
      return [
        diffDays === 1
          ? "tomorrow"
          : d.toLocaleDateString(lang, {
              weekday: "long"
            }),
        ...timeFormatted
      ];
    }
  },

  /*
   Mediator for times of live events.
   sends a clock to appropriate module by looking at clock type
   @param {BetMarketEvent} event
   @return {string[]}
  */
  liveEventTime: (event: BetMarketEvent): string[] => {
    if (event.gameClock.unreliable) {
      return ["-icon-play"];
    }

    if (event.gameClock.sectionType === "penalties") {
      return ["-icon-penalty"];
    }

    switch (event.gameClock.clockType) {
      case 'stopwatch':
        return stopWatch(event.sport, event.gameClock as GameClockStopWatch);
      case 'progress':
        return progress(event.gameClock as GameClockProgress);
      case 'sections':
        return sections(
            event.sport,
            event.gameClock as GameClockSections,
            event.competitor1 as competitorTypeSections,
            event.competitor2 as competitorTypeSections
        );
      default:
        return ['-'];
    }
  }
};

import config from "../configuration";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export enum BetslipSidebarStatuses {
  REQUESTED = "REQUESTED",
  PENDING = "PENDING",
  PRELIMINARY = "PRELIMINARY",
  ALLOWED = "ALLOWED",
  REJECTED = "REJECTED",
  PAID_OUT = "PAID_OUT",
  PAYOUT = "PAYOUT",
  EXPIRED = "EXPIRED",
  CANCELED = "CANCELED",
  BUY_BACK = "BUY_BACK",
  BOUGHT_BACK = "BOUGHT_BACK",
  PAYBACK = "PAYBACK",
  PAID_BACK = "PAID_BACK",
  LOST = "LOST",
  DELIVERED = "DELIVERED",
  WON = "WON",
}

export enum BetslipRowStatuses {
  Open = "Open",
  ToBePaidBack = "ToBePaidBack",
  Canceled = "Canceled",
  BuyBack = "BuyBack",
  Lost = "Lost",
  Won = "Won",
}

export enum CCRowsStatuses {
  REQUESTED = "REQUESTED",
  PENDING = "PENDING",
  PRELIMINARY = "PRELIMINARY",
  TEST = "TEST",
  ALLOWED = "ALLOWED",
  REJECTED = "REJECTED",
  PAID_OUT = "PAID_OUT",
  PAYOUT = "PAYOUT",
  EXPIRED = "EXPIRED",
  CANCELED = "CANCELED",
  BUY_BACK = "BUY_BACK",
  BOUGHT_BACK = "BOUGHT_BACK",
  PAYBACK = "PAYBACK",
  PAID_BACK = "PAID_BACK",
  LOST = "LOST",
  DELIVERED = "DELIVERED",
  WON = "WON"
}

export type CustomerCardResponse = {
  betslips: Array<CustomerCardBetslipsResponse | CustomerCardHeader>;
};

export type CustomerCardBetslipsResponse = {
  id: number;
  status: keyof typeof CCRowsStatuses;
  date: string;
  number: number;
  stake: number;
  prize: number;
  taxFee: number;
  type?: "row";
};

export type CustomerCardHeader = {
  date: string;
  type: "header";
  id: number;
};

export type BetslipResponse = {
  id: number;
  status: keyof typeof BetslipSidebarStatuses;
  date: string;
  number: number;
  system: string;
  oddBoost: number;
  stake: number;
  maxCombinedOdds: number;
  maxCombinedOddsWithOddsBoost: number;
  prize: number;
  maxPrize: number;
  bets: Array<Bets>;
};
export type Bets = {
  id: number;
  date: string;
  status: keyof typeof BetslipRowStatuses;
  eventId: string;
  odds: number;
  teams: string;
  score: string;
  market: string;
  way: number;
  choiceParam: string;
  participantName: string;
};

export type Opts = { endpoint: "betslip" | "customer_card" };

const networkConfig = config.getNetwork();
const axiosConfig: AxiosRequestConfig = {
  timeout: networkConfig.timeoutMs,
};
const http = axios.create(axiosConfig);

const infoTerminalNetwork = {
  async pull(endpoint: string, params: AxiosRequestConfig["params"]) {
    return http.post(endpoint, params);
  },
  generateEndpoint(params: Opts) {
    const acceptChannelId = config.getAcceptChannelId() ?? "";
    switch (params.endpoint) {
      case "betslip":
        return config
          .getEndpoint("betslip")
          .replace("%acceptChannelId%", acceptChannelId);
      case "customer_card":
        return config
          .getEndpoint("customer_card")
          .replace("%acceptChannelId%", acceptChannelId);
      default:
        return "";
    }
  },
  async getBetSlip(betslipId: string): Promise<AxiosResponse<BetslipResponse>> {
    const betslipEndpoint = this.generateEndpoint({ endpoint: "betslip" });
    return await this.pull(betslipEndpoint, { ticketBarcode: betslipId });
  },
  async getCustomerCard(
    ccId: string
  ): Promise<AxiosResponse<CustomerCardResponse>> {
    const customerCardEndpoint = this.generateEndpoint({
      endpoint: "customer_card",
    });
    return await this.pull(customerCardEndpoint, { customerCardBarcode: ccId });
  },
};

export default infoTerminalNetwork;

import React from "react";
import BetGeniusContentWindowMessageProvider, {
  ComponentProps
} from "./BetGeniusContentWindowMessageProvider";
import "./GenericBetGeniusIframeComponent.scss";

interface Props {
  languageCulture: string;
  betGeniusId?: string;
  bgSportId: string;
  component: string;
}

export default class GenericBetGeniusIframeComponent extends React.Component<
  Props
> {
  private bgIframe: HTMLIFrameElement | null;
  private bgContentWindowMessage: string;
  constructor(props: Props) {
    super(props);
    this.bgIframe = null;
    const componentProps: ComponentProps = {
      component: this.props.component,
      bgSportId: this.props.bgSportId
    };
    this.bgContentWindowMessage = BetGeniusContentWindowMessageProvider(
      componentProps
    );
  }

  render(): JSX.Element {
    return (
      <div className={"betgenius-iframe-wrapper"}>
        {/*{this.changeTab(this.props.component)}*/}
        <iframe
          scrolling="no"
          ref={iframe => this.setIframe(iframe)}
          id="betgenius-iframe"
          className={"betgenius-iframe"}
          src={this.getbetGeniusLink()}
        />
      </div>
    );
  }

  componentDidMount(): void {
    this.setIframeWindow(this.bgContentWindowMessage);
  }

  componentWillReceiveProps(): void {
    this.bgIframe !== null &&
      this.bgIframe.contentWindow !== null &&
      this.bgIframe.contentWindow.postMessage(this.bgContentWindowMessage, "*");
  }

  private setIframe(iframe: HTMLIFrameElement | null) {
    this.bgIframe = iframe;
  }

  private setIframeWindow(message: string) {
    this.bgIframe !== null &&
      this.bgIframe.contentWindow !== null &&
      this.bgIframe.contentWindow.postMessage(message, "*");
  }

  private getbetGeniusLink() {
    const host = "tipico";

    // tslint:disable-next-line
    return `https://${host}.betstream.betgenius.com/betstream-view/page/tipico/${
      this.props.bgSportId
    }?culture=${this.props.languageCulture}&eventId=${this.props.betGeniusId}`;
  }
}

import { GameClockProgress } from "modules/InfoScreens/types";

/*
 A live event progress game clock type processing
 basketball, ...
 @param {GameClockProgress} clock
 @returns {string[]} current progress(%), section number, section name i18n-key
*/
const progress = (clock: GameClockProgress) => {
    if (clock.eventState !== 'running') {
        return [clock.techDisplayName];
    }

    let translationKey = clock.techDisplayName;
    let section = `${clock.sectionNumber}. `;
    if (clock.sectionType === 'overtime') {
        translationKey = 'ot';
        section = '';
    }

    return [
        `${clock.sectionProgress}`,
        `${section}`,
        `${translationKey}`
    ];
}

export default progress;

import * as React from "react";
import classnames from "classnames";

import {
  SportEvent as TennisSportEvent,
  Set
} from "../ScoreCenter/Tennis/TennisLive/model";
import "./TennisScoreBoard.scss";

interface Props {
  sportEvent: TennisSportEvent;
}

function TennisScoreBoard(props: Props) {
  const event = props.sportEvent.event;

  if (!event) {
    return null;
  }

  const eventsScore = event.scores || [
    event.set1,
    event.set2,
    event.set3,
    event.set4,
    event.set5
  ];
  const cssTeamOne = classnames(
    "circle",
    !event.currentService || event.currentService === 2 ? "hidden" : ""
  );
  const cssTeamTwo = classnames(
    "circle",
    !event.currentService || event.currentService === 1 ? "hidden" : ""
  );

  return (
    <div className={"scorecenterTennis"}>
      <div className={"teams"}>
        <div className={"date-time"}>{event.date || event.dateAndTime}</div>
        <div>
          <span className={"team-one-color"}>&#124;</span>
          {event.team1}
        </div>
        <div>
          <span className={"team-two-color"}>&#124;</span>
          {event.team2}
        </div>
      </div>
      <div className={"score"}>
        <table>
          <tbody>
            <tr className={"set-number"}>{renderSetNumbers(eventsScore)}</tr>
            <tr>
              {renderScoreList(eventsScore, event.currentSection, true)}

              {event.pointsSet ? (
                <td className={"current-game"}>{event.currentGame.count1}</td>
              ) : null}

              <td className={"current-set"}>
                <div className={cssTeamOne} />
              </td>
            </tr>
            <tr>
              {renderScoreList(eventsScore, event.currentSection, false)}

              {event.pointsSet && (
                <td className={"current-game"}>{event.currentGame.count2}</td>
              )}

              <td className={"current-set"}>
                <div className={cssTeamTwo} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function renderSetNumbers(eventsScore: Set[]) {
  return eventsScore.map((event, index) => {
    if (event.count1 || event.count2) {
      return <td key={index}>{index + 1}.</td>;
    } else {
      return null;
    }
  });
}

function renderScoreList(
  eventsScore: Set[],
  currentSection: number,
  topScore: boolean
): Array<JSX.Element | null> {
  return eventsScore.map((event, index) => {
    if (event.count1 !== "") {
      if (topScore) {
        return (
          <td
            key={index}
            className={getClass(
              Number(event.count1),
              Number(event.count2),
              currentSection,
              index
            )}
          >
            {event.count1}
          </td>
        );
      }

      return (
        <td
          key={index}
          className={getClass(
            Number(event.count2),
            Number(event.count1),
            currentSection,
            index
          )}
        >
          {event.count2}
        </td>
      );
    }

    return null;
  });
}

function getClass(
  event1: number,
  event2: number,
  currentSection: number,
  numberSection: number
): string {
  return classnames(
    event1 > event2 ? "winner" : "",
    currentSection === numberSection + 1 ? "current" : ""
  );
}

export default TennisScoreBoard;

import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import * as rax from 'retry-axios';

import dynamicSettings from "../dynamicSettings";
import config from "../configuration";
import {BetMarketEvent, BetMarketHeader, LicenseDataType} from "modules/InfoScreens/types";

const networkConfig = config.getNetwork();
const axiosConfig: AxiosRequestConfig = {
    timeout: networkConfig.timeoutMs
}

export const http = axios.create(axiosConfig);
http.defaults.raxConfig = {
    instance: http,
    retry: networkConfig.retryTimes,
    noResponseRetries: networkConfig.retryTimes,
    statusCodesToRetry: [[100, 199], [303, 599]],
    onRetryAttempt: (err) => {
        const cfg = rax.getConfig(err);
        console.warn(`${err.config.url} endup with error. code: ${err.code}, message: ${err.message}`);
        console.warn(`retry attempt #${cfg?.currentRetryAttempt}`);
    }
};
rax.attach(http);

const eventsNetwork = {
    /*
    Go for data
    @param {endpoint} PDS API endpoint URL
    */
    async pull(endpoint: string) {
        return http.get(endpoint);
    },

    /*
    Construct endpoint
    @param {endpointName} PDS API endpoint identity
     */
    generateEndpoint(endpointName: string) {
        switch (endpointName) {
            case "events":
                const license = dynamicSettings.getLicense();
                if (license === 'update-license') {
                    throw new Error('License is invalid. Generate a license first');
                }
                const leagueId = config.getLeagueId();
                const page = config.getPage();
                const endpoint = config
                    .getEndpoint("events")
                    .replace("%license%", license)
                    .replace("%page%", page);

                return !leagueId ? endpoint : `${endpoint}?groupId=${leagueId}`;
            case "license":
                const acceptChannelId = config.getAcceptChannelId() as string;

                return config
                    .getEndpoint("license")
                    .replace("%acceptChannelId%", acceptChannelId);
            default:
                return "";
        }
    },

    /*
    Get License ID from dynamicSettings or from PDS API.
    License to be updated in an interval
    specified in the request response body
    */
    async retrieveLicenseId(): Promise<AxiosResponse<LicenseDataType>> {
        const licenseEndpoint = this.generateEndpoint("license");
        return await this.pull(licenseEndpoint);
    },

    /*
    Get events from PDS API
    */
    async retrieveEvents(): Promise<AxiosResponse<(BetMarketHeader | BetMarketEvent)[]>> {
        const eventsEndpoint = this.generateEndpoint("events");
        return await this.pull(eventsEndpoint);
    },

    /*
    Drop license after TTL expired
    @param {ttlSeconds}
    */
    licenseUpdate(ttlSeconds: number) {
        const licenseTtlMs = ttlSeconds * 1000;

        setTimeout(() => {
            dynamicSettings.setLicense(config.getUpdateLicenseName());
        }, licenseTtlMs);
    }
};

export default eventsNetwork;

import {MatchTickerHighLight} from "../../modules/InfoScreens/types";
import highlightsStack, {Stack} from "./highlightsStack";

const highlight = {
  processHighlight(highlight: MatchTickerHighLight) {
    highlightsStack.initHighlights();
    highlightsStack.addToStack({...highlight});
  },
  refresh() {
    highlightsStack.clearStack();
  },
  normalizer(input: MatchTickerHighLight[]): Stack {
    const inputLen = input.length;
    for (let i = 0; i < inputLen; i++) {
      const eventRaw = input[i] as MatchTickerHighLight;
      this.processHighlight(eventRaw);
    }
    return highlightsStack.all();
  }
};

export default highlight;
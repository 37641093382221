import React, {FC} from "react";

interface SoccerProps {
  height: number,
  width: number
}

const Penalty: FC<SoccerProps> = (props) => {
  return <div className="penalty-mt-ico" style={{height: props.height, width: props.width}} />
}

export default Penalty;
import React, { FC, useEffect, useState } from "react";
import ContentRouter from "./ContentRouter";
import InfoRouter from "./InfoRouter";
import "./InfoTerminalBase.scss";
import { useLocation, useParams, useHistory } from "react-router-dom";
import InfoTerminal, {
  BetslipResponse,
  CustomerCardResponse,
} from "../../../services/infoTerminal/infoNetwork";
import BarcodeScanner from "../components/BarcodeScanner";
import infoTerminalService from "../../../services/infoTerminal";
import GlobalLoader from "../../common/GlobalLoader";
import NoData from "../components/rows/NoData";
import dynamicSettings from "../../../services/dynamicSettings";
import InfoTerminalTranslations from "../components/translations";

let inactivity: ReturnType<typeof setTimeout>;
const inactivityTimerS = 15;

const routes = {
  betslip: "/betslip",
  customerCard: "/customer-card",
};

let ccBarcode: string;
let pageCC: number;

const Container: FC = () => {
  const location = useLocation();
  const eT = dynamicSettings.getTranslation("errors");
  const params: { betslipId?: string; ccId?: string } = useParams();
  const [data, setData] = useState<
    BetslipResponse | CustomerCardResponse | null
  >();
  const [page, setPage] = useState(0);
  const [barcode, setBarcode] = useState("");
  const [type, setType] = useState<"CC" | "BS" | null>();
  const history = useHistory();
  const [fetching, setFetching] = useState(true);
  const [noData, setNoData] = useState(false);
  const [placeholder, setPlaceholder] = useState<string>("");

  useEffect(() => {
    fetchInfo();
  }, [location]);

  useEffect(() => {
    pageCC = page;
    inactivityTimer();
  }, [page]);

  useEffect(() => {
    ccBarcode = barcode;
    if (barcode.length > 0) {
      if (type === "CC") {
        history.push(
          `/info-terminal/customer-card/${barcode}${location.search}`
        );
      }
      if (type === "BS") {
        history.push(`/info-terminal/betslip/${barcode}${location.search}`);
      }
    }
  }, [type, barcode]);

  useEffect(() => {
    if (params.ccId && params.ccId.length > 0) {
      setBarcode(params.ccId);
      setType("CC");
    }
    if (params.betslipId && params.betslipId.length > 0) {
      setType("BS");
      setBarcode(params.betslipId);
    }
  }, []);

  const fetchInfo = () => {
    setFetching(true);
    setNoData(false);
    if (
      location.pathname.includes(routes.betslip) &&
      params.betslipId &&
      type === "BS"
    ) {
      InfoTerminal.getBetSlip(params.betslipId)
        .then((res) => {
          setFetching(false);
          // to start the inactivity timer even for betslip calls
          setPage(0);
          setData(res.data);
        })
        .catch((e) => {
          setFetching(false);
          setPlaceholder(eT(`${e.response.status}-infoterminal`));
          setNoData(true);
          setBarcode("");
          setPage(0);
        });
    }
    if (
      location.pathname.includes(routes.customerCard) &&
      params.ccId &&
      type === "CC"
    ) {
      InfoTerminal.getCustomerCard(params.ccId)
        .then((res) => {
          setFetching(false);
          setPage(0);
          if (res?.data?.betslips?.length <= 0) {
            setNoData(true);
            setPlaceholder(eT("no-data-infoterminal"));
          } else {
            res.data.betslips.sort((a, b) => {
              return new Date(b.date).valueOf() - new Date(a.date).valueOf();
            });
            setData(res.data);
          }
        })
        .catch((e) => {
          setFetching(false);
          try {
            setPlaceholder(eT(`${e.response.status}-infoterminal`));
          } catch (e) {
            setPlaceholder(eT(`generic`));
          }
          setPage(0);
          setNoData(true);
          setBarcode("");
        });
    }
  };

  BarcodeScanner({
    returnValue: (bc, type) => {
      if (bc === ccBarcode) {
        const totalPages = infoTerminalService.getTotalPages();
        if (totalPages && totalPages > pageCC) {
          setPage(pageCC + 1);
        } else {
          setPage(0);
        }
      } else {
        setBarcode(bc);
        setType(type);
      }
    },
  });

  const inactivityTimer = () => {
    clearTimeout(inactivity);
    inactivity = setTimeout(() => {
      history.push(`/info-terminal-screen-saver${location.search}`);
    }, inactivityTimerS * 1000);
  };

  return (
    <InfoTerminalTranslations>
      <div className="info-terminal--base">
        {noData ? (
          <div className="placeholder__wrapper">
            <NoData message={placeholder} />
          </div>
        ) : (
          <>
            <div className="content__column">
              <ContentRouter
                page={page}
                {...(data as BetslipResponse | CustomerCardResponse)}
              />
            </div>
            <div className="info__column">
              <InfoRouter
                page={page}
                {...(data as BetslipResponse | CustomerCardResponse)}
              />
            </div>
            <GlobalLoader show={fetching} />
          </>
        )}
      </div>
    </InfoTerminalTranslations>
  );
};

export default Container;

import React, {FC} from "react";
import {Bets, BetslipRowStatuses} from "../../../../../services/infoTerminal/infoNetwork";
import "./betslip.scss";
import dayjs from 'dayjs';
import dynamicSettings from "../../../../../services/dynamicSettings";
import configuration from "../../../../../services/configuration";
import {getLocaledString} from "../../../../../services/helper";

const BetslipRows: FC<Bets> = ({
  id,
  date,
  status,
  eventId,
  odds,
  teams,
  score,
  market,
  choiceParam,
  participantName
                           }) => {

  const t = dynamicSettings.getTranslation("infoterminal");
  const lang = configuration.getLanguage();


  return (
    <div className={"betslip-row-container " + status}>
      <div className="betslip__number">
        <div>
          {eventId}
        </div>
      </div>
      <div className="betslip__betslip-details">
        <div className="betslip___date">{date ? dayjs(date).format("MMM. DD, YYYY, hh:mm A") : <br />}</div>
        <div className="betslip___teams">{teams}</div>
        <div className="betslip___market">{market} {choiceParam} {participantName}</div>
      </div>
      <div className="betslip__game-details">
        <div className="game___score_time">{score || (date ? dayjs(date).format('h:mm A') : <br />)}</div>
        <div className="game___odds_status__wrapper">
          <div className="game___odds">
            {odds > 0 ? getLocaledString(Number(odds.toFixed(2))) : ""}
          </div>
          <div className={"game___status " + status}>
            {t(`bet-status-${BetslipRowStatuses[status]}`)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BetslipRows;
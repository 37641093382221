import React, { ReactElement } from "react";

import dynamicSettings from "../../../services/dynamicSettings";

const translationsKey = "errors";

const WarningContent = ({
  infoMain,
  infoSub,
  bgClass
}: {
  infoMain: string;
  infoSub: string;
  bgClass?: string;
}): ReactElement => {
  if (!infoMain) {
    return <></>;
  }

  const t = dynamicSettings.getTranslation(translationsKey);

  return (
    <div className={`app-warning-wrapper ${bgClass}`}>
      <div className={"app-warning-container"}>
        <div className="app-warning__content">
          <div className="app-warning__content-main">{t(infoMain)}</div>
          <div className="app-warning__content-sub">
            {!!infoSub ? t(infoSub) : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningContent;

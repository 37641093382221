import React, {FC} from 'react';
import {
  CCRowsStatuses,
  CustomerCardBetslipsResponse
} from "../../../../../services/infoTerminal/infoNetwork";
import dayjs from "dayjs";
import dynamicSettings from "../../../../../services/dynamicSettings";
import {getLocaledString} from "../../../../../services/helper";

const CustomerCardRows: FC<CustomerCardBetslipsResponse & { addBottomRadius: boolean, count: number }> = (props) => {
  const t = dynamicSettings.getTranslation("infoterminal");
  return (
    <div
      className={
        "customer-card__row___container " +
        (props.addBottomRadius && "bottom__radius")
      }
    >
      <div className="customer-card__row___wrapper">
        <span>
          <span>
            {t("number")} {props.number}
            <span
              id={"customer-card-row-status-"+props.count}
              className={"customer-card__row___status " + props.status}
            >
              {t(`betslip-status-${CCRowsStatuses[props.status]}`)}
            </span>
          </span>
          <p>{dayjs(props.date).format("hh:mm A")}</p>
        </span>
        <span>{getLocaledString(Number(props.stake.toFixed(2)), true)}</span>
        <span>{props.prize ? getLocaledString(props.prize, true) : "-"}</span>
      </div>
    </div>
  );
}

export default CustomerCardRows;
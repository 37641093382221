import React, {FC} from "react";

const NoData: FC<{message: string}> = ({message}) => {
  return (
    <div className="no-data__placeholder___container">
      <div className="no-data__placeholder___wrapper">
        <img className="no-data__placeholder___img" alt="" />
        <p>{message}</p>
      </div>
    </div>
  )
}

export default NoData;
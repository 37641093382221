import React, { FunctionComponent } from 'react';
import classNames from "classnames";

import { classes } from "../EventRow/EventRow";

interface IdProps {
    id: string;
    onTop: boolean;
    code: string;
}

const Id: FunctionComponent<IdProps> = ({ id, onTop, code }) => {
    const classesWrapper = classNames({
        [classes.basicFlex]: onTop,
        [classes.lwkEventsCol]: true,
        [classes.vertFlex]: onTop
    });

    return (
        <div className={classesWrapper}>
            {onTop
                ? <span className={classes.vertSplitClass} data-id={id}>{code}</span>
                : <span data-id={id}>{code}</span>
            }
            {onTop && <span className="cell--vertical-half -bg-custom -bg-reddish -text-up">top</span>}
        </div>
    );
};

export default Id;

import { competitorTypeSections, GameClockSections } from "modules/InfoScreens/types";
import { verifyIntegers } from "services/helper";
import config from "services/configuration";

/*
 A live event progress game clock type sections
 tennis, ...
 @param {string} sport
 @param {GameClockSections} clock
 @param {competitorTypeSections} competitor1 - stats for team1
 @param {competitorTypeSections} competitor2 - stats for team2
 @returns {string[]} current section number, section name i18n-key, score by games
*/
const sections = (
    sport: string,
    clock: GameClockSections,
    competitor1: competitorTypeSections,
    competitor2: competitorTypeSections
) => {

    if (clock.eventState === "interrupted") {
        return ["-icon-rain"];
    }
    if (clock.eventState !== 'running') {
        return [clock.techDisplayName];
    }

    const immediateScoreBySports: {
        [sportName: string]: keyof competitorTypeSections;
    } = config.getSpecialTimeDataKeys();
    const translationKey = clock.techDisplayName;
    const section = `${clock.sectionNumber}. `;

    const time = [
        `${section}`,
        `${translationKey}`
    ];

    const immediateScoreKey = immediateScoreBySports[sport] || immediateScoreBySports.default;

    if (verifyIntegers(competitor1[immediateScoreKey], competitor2[immediateScoreKey])) {
        time[2] = `${competitor1[immediateScoreKey]} : ${competitor2[immediateScoreKey]}`;
    }

    return time;
}

export default sections;

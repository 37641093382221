import React, {FC, useEffect, useState} from "react";
import {
  CustomerCardBetslipsResponse,
  CustomerCardHeader,
  CustomerCardResponse
} from "../../../services/infoTerminal/infoNetwork";
import infoTerminalService from "../../../services/infoTerminal";
import CustomerCardHeaderRow from "../components/rows/CustomerCardRows/CustomerCardHeader";
import "./../components/rows/CustomerCardRows/customercard.scss";
import CustomerCardRows from "../components/rows/CustomerCardRows/CustomerCardRows";

const CustomerCard: FC<CustomerCardResponse & {page: number}> = ({betslips, page}) => {

  const [data, setData] = useState<Array<Array<CustomerCardBetslipsResponse | CustomerCardHeader>>>([]);

  useEffect(() => {
    if(betslips?.length > 0) {
      setData(infoTerminalService.consume(betslips));
    }
  }, [betslips]);

  return (
    <div>
      {
        data[page]?.map((item, idx) => {
          if(item.type === "header") {
            return <CustomerCardHeaderRow {...item} key={idx}/>
          }
          if(item.type === "row") {
            const addBottomRadius = data[page][idx+1]?.type === "header" || !data[page][idx+1];
            return <CustomerCardRows addBottomRadius={addBottomRadius} {...item} key={idx} count={idx} />
          }
        })
      }
    </div>
  )
}

export default CustomerCard;
import React, { FC } from "react";

import HeaderOddNamesExplain from "./HeaderOddNamesExplain";
import { betMarketHeadersType } from "../../types";
import "./HeaderOddName.scss";
import HeaderOddNamesTitle from "./HeaderOddNamesTitle";
import { betmarketName } from "services/helper";

const HeaderOddNames: FC<{ bmHeader: betMarketHeadersType, sport: string }> = props => {
  const { bmHeader, sport } = props;
  const title = betmarketName(bmHeader.name, sport);

  return (
    <div
      className="basic-flex lwk-events-col -border-left-000-2"
      style={{ flexDirection: "column" }}
    >
      <HeaderOddNamesTitle title={title} key={bmHeader.id} />
      <div className="basic-flex odd__title--holder-explain">
        {bmHeader.oddNames &&
          bmHeader.oddNames.map((odd: string, idx: number) => {
            return <HeaderOddNamesExplain odd={odd} key={`${odd}${idx}`} />;
          })}
      </div>
    </div>
  );
};

export default HeaderOddNames;

import React, { FC } from 'react';
import classNames from "classnames";

import Serve from "./Serve";
import { NormalizedEvents } from "modules/InfoScreens/types";
import { classes } from "../../EventRow/EventRow";
import dynamicSettings from "services/dynamicSettings";

interface SectionsScoreProps {
    sport: string;
    score: NormalizedEvents['score']['values'];
}

const SectionsScore: FC<SectionsScoreProps> = ({sport, score}) => {
    const scoreColWidth = dynamicSettings.getScoreColWidth();
    const classesWrapper = classNames({
        [classes.basicFlex]: true,
        [classes.lwkEventsCol]: true,
        [classes.vertFlex]: true,
        [classes.borderLeft]: true
    });
    const classesTop = classNames({
        [classes.vertSplitClass]: true,
        [classes.yellowClass]: true,
        [classes.font.size28]: true,
        [classes.relative]: true
    });
    const classesBottom = classNames({
        [classes.vertSplitClass]: true,
        [classes.bg.custom]: true,
        [classes.bg.grey45]: true
    });

  return (
      <div className={classesWrapper} style={{width: `${scoreColWidth}px`}}>
          <div className={classesTop}>
              <Serve sport={sport} score={score[0]} />
              <span>{score[1][0]}</span> : <span>{score[1][1]}</span>
          </div>
          <div className={classesBottom}>
              <span>{score[2][0]}</span> : <span>{score[2][1]}</span>
          </div>
      </div>
  );
};

export default SectionsScore;

import { useEffect } from "react";

import config from "services/configuration";
import {LicenseDataType, PullEventsCB} from "modules/InfoScreens/types";
import eventsService from "services/events";
import network from "services/events/eventsNetwork";
import loadTranslation from "services/translation";
import dynamicSettings from "services/dynamicSettings";
import pickupLine from "services/pickupLine";
import { AxiosResponse } from "axios";
import eventsNetwork from "services/events/eventsNetwork";
import eventsStack from "services/events/eventsStack";
import pickupLineMT from "../../../services/pickupLineMT";
import highlightsStack from "../../../services/highlights/highlightsStack";

const pullInterval = config.getPullingInterval();
const updateLicense = config.getUpdateLicenseName();
const errorLicense = config.getErrorLicenseName();
const pendingLicense = config.getPendingLicenseName();
const noop = () => {};
export const resetServicesOnError = () => {
    eventsStack.reInit();
    pickupLine.invalidate();
    pickupLineMT.invalidate();
    highlightsStack.reInit();
}

/*
* 3 ever running intervals, each triggered only once. on page load.
* Unordered:
* a) makes the actual events request and stores data in "safe place"
* b) takes data from "safe place" and delivers them to UI
* c) retrieves and updates a license ID
     …
*  (˚∆˚)
*   {-}
*    ¥
* ----------
* Objective:
* decouple pulling from events processing. In a case of network error
* or a delay on transport layer continue to deliver events to UI.
* in a case of network problems it will be a result of last succeeded request.
* Fallback to error page is triggered by actual pulling element.
*/
const useEvents = (cb: PullEventsCB) => {
    useEffect(() => {
        // take events from "safe place"
        const idEventsInterval = window.setInterval(() => {
            const eventsRaw = pickupLine.consume();
            if (eventsRaw) {
                // process headers, revert dynamic values & shift stack
                eventsService.refresh(eventsRaw.headers);
                const eventsUI = eventsService.normalizer(eventsRaw.events);
                cb(new Error(), { ...eventsUI, error: new Error(), eventsReady: true });
            }
        }, pullInterval);

        return () => window.clearInterval(idEventsInterval);
    }, []);

    useEffect(() => {
        // call for license
        // 200 => set license expire t/o
        async function getLicense(): Promise<void> {
            try {
                dynamicSettings.setLicense(pendingLicense);
                // retries are happening here
                const licenseRes: AxiosResponse<LicenseDataType> = await network.retrieveLicenseId();
                dynamicSettings.setLicense(licenseRes.data.licenseKey);
                eventsNetwork.licenseUpdate(licenseRes.data.ttlSeconds);
            } catch (e: any) {
                // error => refresh stack,
                // invalidate events,
                // set license value to 'update-license'
                cb(e);
                resetServicesOnError();
                dynamicSettings.setLicense(errorLicense);
                eventsNetwork.licenseUpdate(config.getLicenseTtlDefault());
            }
        }

        // get initial license onload
        setTimeout(async () => await getLicense(), 0);

        // update license ID
        const idLicenseInterval = window.setInterval(
          async () => {
              const license = dynamicSettings.getLicense();
              if (license === updateLicense) {
                  await getLicense();
              }
          },
          1000
        );

        return () => window.clearInterval(idLicenseInterval);
    }, []);

    useEffect(() => {
        async function getEvents() {
            try {
                dynamicSettings.setTransportStatus("busy");
                // retries are happening there
                const eventsRes = await network.retrieveEvents();
                // safe raw events in "safe place"
                pickupLine.rollout(eventsRes.headers, eventsRes.data);
                dynamicSettings.setTransportStatus("ready");
            } catch (e: any) {
                // set t/o to change transport status to ready
                // after a predefined delay
                // objective: after error continue to pull after a delay
                cb(e);
                resetServicesOnError();
                setTimeout(() => {
                    dynamicSettings.setTransportStatus("ready");
                }, config.getEventsWaitOnError() * 1000);
            }
        }

        loadTranslation("infoscreens").then(() => {
            cb(new Error(),{ translationsReady: true });
        });

        // start events pulling interval
        const idEventsInterval = window.setInterval(
            () => {
                const status = dynamicSettings.getTransportStatus();
                const validLicense = ![updateLicense, errorLicense, pendingLicense].includes(dynamicSettings.getLicense());
                if (status === 'ready' && validLicense) {
                    getEvents().then(noop);
                }
            },
            pullInterval
        );

        return () => window.clearInterval(idEventsInterval);
    }, []);
}

export default useEvents;

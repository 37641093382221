import { BetMarketHeader, BetMarketEvent } from "modules/InfoScreens/types";

let eventsLatest: {
  headers: Record<string, string>,
  events: (BetMarketHeader | BetMarketEvent)[]
}  | undefined;

export default {
  rollout(headers: Record<string, string>, events: (BetMarketHeader | BetMarketEvent)[]) {
    eventsLatest = { headers, events };
  },

  consume() {
    return eventsLatest;
  },

  invalidate() {
    eventsLatest = undefined;
  },

  invalidateAfter(periodMs: number) {
    setTimeout(() => {
      eventsLatest = undefined;
    }, periodMs);
  }
}

import * as React from "react";

import { EventPointList } from "../Soccer/SoccerLive/model";

import "./styles.scss";
import {
  TimeLineDivItem,
  TimeLineDivItemWithScore,
  TimeLineSpanItem
} from "./TimeLineItem";

interface Props {
  data?: EventPointList[];
}

interface State {
  openPitch: boolean;
}

export default class BetGeniusScorePitch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { openPitch: true };
  }

  render(): JSX.Element | null {
    const { data: eventList } = this.props;

    if (!eventList || eventList.length === 0) {
      return null;
    }

    const team1Event = eventList[0].evTeam1 ? (
      <TimeLineSpanItem
        team={eventList[0].evTeam1}
        score={eventList[0].score}
      />
    ) : null;
    const team2Event = eventList[0].evTeam2 ? (
      <TimeLineSpanItem
        team={eventList[0].evTeam2}
        score={eventList[0].score}
      />
    ) : null;

    const isHide = this.state.openPitch ? "" : "hide";
    const scoreListCSS = "pitch-container " + isHide;
    return (
      <section className={"score-pitch"}>
        <div className={"pitch-row top-section"}>
          <div className={"pitch-column pitch-right"}>{team1Event}</div>
          <div className={"pitch-square minute"}>{eventList[0].minute}</div>
          <div className={"pitch-column"}>{team2Event}</div>
        </div>
        <ul className={scoreListCSS}>{this.renderScoreList(eventList)}</ul>
      </section>
    );
  }

  private renderScoreList(pointList: EventPointList[]) {
    const [, ...rest] = pointList.map((event, index) => {
      if (index < 6) {
        const pitchRowCSS = "pitch-column pitch-right";
        const pitchColumnCss = "pitch-square transparent";
        if (event.evTeam1 !== "" && event.isHt === false) {
          return (
            <li key={index} className={"pitch-row"}>
              <TimeLineDivItemWithScore
                className={pitchRowCSS}
                score={event.score}
                team={event.evTeam1}
              />
              <div className={"pitch-square"}>{event.minute}</div>
              <TimeLineDivItem
                className={"pitch-column"}
                team={event.evTeam2}
              />
            </li>
          );
        } else if (event.isHt === true) {
          return (
            <li key={index} className={"pitch-row pitch-ht"}>
              <div className={"pitch-column"} />
              <div className={pitchColumnCss}>{event.minute}</div>
              <div className={"pitch-column"} />
            </li>
          );
        } else {
          return (
            <li key={index} className={"pitch-row"}>
              <TimeLineDivItem className={pitchRowCSS} team={event.evTeam1} />
              <div className={"pitch-square"}>{event.minute}</div>
              <TimeLineDivItemWithScore
                className={"pitch-column"}
                score={event.score}
                team={event.evTeam2}
              />
            </li>
          );
        }
      }
    });
    return rest;
  }
}

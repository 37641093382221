import React, { FC, useMemo } from "react";

import dynamicSettings from "services/dynamicSettings";
import { getTextWidth } from "services/helper";

const translationsKey = "infoscreens-lwk-headers";

const HeaderOddNamesTitle: FC<{ title: string }> = props => {
  const t = dynamicSettings.getTranslation(translationsKey);
  const title = props.title && t(props.title);
  const titleWidth = useMemo<number>(() => getTextWidth(title, 'technical-span2'), [title]);

  let longHeaderClass = "";
  if (title && titleWidth > 256) {
    longHeaderClass = " -long-header";
  }

  return (
    <div className={`odd__title -h-40${longHeaderClass}`}>
      <span>{title}</span>
    </div>
  );
};

export default HeaderOddNamesTitle;

import React, { FC } from 'react';

import { classes } from "../../EventRow/EventRow"
import dynamicSettings from "services/dynamicSettings";

interface UpcomingProps {
  sport: string;
}

const Upcoming: FC<UpcomingProps> = ({sport}) => {
  const scoreColWidth = dynamicSettings.getScoreColWidth();

  return (
      <div className={`${classes.eventTeams} ${classes.borderLeft} -block`} style={{width: `${scoreColWidth}px`}}>
        <span className={`-icon-${sport} -upcoming-event-icon`} />
      </div>
  );
};

export default Upcoming;

import React, { FC, useMemo } from 'react';

import { competitorAggregatedPoints, competitorType } from "../../types";
import classNames from "classnames";
import dynamicSettings from "services/dynamicSettings";
import { getTextWidth } from "services/helper";

interface TeamsProps {
    competitor1: competitorType;
    competitor2: competitorType;
}

const teamsDelimeterSize = 24;
const halfTeamsDelimeterSize = 12;
const teamPointwidth = 24;

export const competitorEventPoints = (
    eventPoints: competitorAggregatedPoints = {},
    position: string
) => {
    const pKeys = Object.keys(eventPoints);
    return (
        pKeys.map((pType) => {
            const amount = eventPoints[pType as keyof competitorAggregatedPoints] || 0;
            return (
                <span
                    className={`-icon-event-point -icon-${pType} -${position}`}
                    key={pType}
                >
                    {amount > 1 ? amount : ""}
                </span>
            );
        })
    );
};

export const getTeamPointsWidth = (teamPoints: ReturnType<typeof competitorEventPoints>): number => {
    return teamPoints.length ? teamPoints.length * teamPointwidth : 0;
}

export const getTeamHTMLWidth = (width: string): {width: string} => {
    return { width: `${width}` }
}

const classesBlock = {
    events__team: true,
    "-border-left-000-2": true
};

const Teams: FC<TeamsProps> = ({competitor1, competitor2}) => {
    const scoreColWidth = useMemo(() => dynamicSettings.getScoreColWidth(), []);
    const team1Points = useMemo(() => competitorEventPoints(competitor1.aggregatedPoints, "left"), [competitor1.aggregatedPoints]);
    const team2Points = useMemo(() => competitorEventPoints(competitor2.aggregatedPoints, "right"), [competitor2.aggregatedPoints]);
    const team1PointsWidth = getTeamPointsWidth(team1Points);
    const team2PointsWidth = getTeamPointsWidth(team2Points);
    const teamsWidthHalf = useMemo(
      () => (document.body.offsetWidth * 33.3 / 100 - scoreColWidth - team1PointsWidth - team2PointsWidth) * 50 / 100 - teamsDelimeterSize,
      [competitor1.aggregatedPoints, competitor2.aggregatedPoints]
    );

    const team1 = String.prototype.trim.call(competitor1.name);
    const team2 = String.prototype.trim.call(competitor2.name);
    const techWidthTeam1 = useMemo(() => getTextWidth(team1), [competitor1.name]);
    const techWidthTeam2 = useMemo(() => getTextWidth(team2), [competitor2.name]);

    let styleTeam1 = getTeamHTMLWidth(`${techWidthTeam1 + 3}px`);
    let styleTeam2 = getTeamHTMLWidth(`${techWidthTeam2 + 3}px`);
    /* if both exceed the half width */
    // equally distribute a space for them
    // (another option is % of weighted length)
    if (techWidthTeam1 > teamsWidthHalf && techWidthTeam2 > teamsWidthHalf) {
        styleTeam1 = getTeamHTMLWidth(`calc(50% - ${halfTeamsDelimeterSize - 6 + team1PointsWidth}px)`);
        styleTeam2 = getTeamHTMLWidth(`calc(50% - ${halfTeamsDelimeterSize + team2PointsWidth}px)`);
    }
    /* if both less then half */
    // do nothing.
    /* if t1 > half and t2 < half */
    else if (techWidthTeam2 < teamsWidthHalf && techWidthTeam1 > teamsWidthHalf) {
        const calcTeamWidth = Math.min(techWidthTeam1 + 6, teamsWidthHalf + halfTeamsDelimeterSize + (teamsWidthHalf - techWidthTeam2));
        styleTeam1 = getTeamHTMLWidth(`${calcTeamWidth}px`);
    }
    /* if t1 < half and t2 > half */
    else if (techWidthTeam2 > teamsWidthHalf && techWidthTeam1 < teamsWidthHalf) {
        const calcTeamWidth = Math.min(techWidthTeam2 + 3, teamsWidthHalf + halfTeamsDelimeterSize + (teamsWidthHalf - techWidthTeam1));
        styleTeam2 = getTeamHTMLWidth(`${calcTeamWidth}px`);
        styleTeam1 = getTeamHTMLWidth(`${techWidthTeam1 + 6}px`);
    }

    return (
        <div
            className={classNames(classesBlock)}
            style={{width: `calc(100% - ${scoreColWidth}px)`}}
        >
            {team1Points}
            <span className="team1" style={styleTeam1}>{team1}</span>
            <span className="team-dash">&nbsp;-&nbsp;</span>
            <span className="team2" style={styleTeam2}>{team2}</span>
            {team2Points}
        </div>
    );
};

export default Teams;

import React, { FC, ReactElement } from "react";

import config from "services/configuration";

/*
FC MetaStateCheck
Application critical meta state check
*/
const MetaStateCheck: FC<{ children: React.ReactElement }> = (props: {
  children: ReactElement;
}) => {
  const baseView = config.getBaseView();
  const acceptChannelId = config.getAcceptChannelId() || "";

  if (
    !["/", "pitch-animation", "info-terminal-screen-saver"].includes(baseView) &&
    isNaN(parseInt(acceptChannelId, 10))
  ) {
    throw new Error("Critical parameter is missing: acceptChannelId");
  }

  return props.children;
};

export default MetaStateCheck;

import * as i18n from "./i18n";
import config from "./configuration";
import * as eventsService from "./events";
import axios from "axios";
function pingGoogle() {
  return new Promise(((resolve) => {
    axios.request({
      method: "GET",
      timeout: config.getRequestTimeout(),
      url: `/?timeStamp=${new Date().getTime()}`
    }).then(() => resolve(true)).catch(() => resolve(false));
  }))
}

export { i18n, config, eventsService, pingGoogle };

import React, {FC} from "react";
import "./HighlightRow.scss";
import {MatchTickerHighLight} from "../../types";
import Competitor from "./Competitor";
import TimeBox from "./TimeBox";
import classNames from "classnames";

const HighlightRow: FC<MatchTickerHighLight> = (props) => {

  const {
    competitor1,
    competitor2,
    type,
    participant,
    focus,
    removed,
    addedAt,
    noAnimation
  } = props;

  // console.log(noAnimation, "no animation");

  const WrapperClassBlock = {
    "basic-flex": true,
    "highlights__main--row": true,
    "highlights__main--row-active": !removed && !noAnimation,
    "highlights__main--row-focus": focus,
    "highlights__main--row-removed": removed || noAnimation
  };

  return (
    <div className={classNames(WrapperClassBlock)}>
      <div className="animated__area--highlight-main">
        <TimeBox {...props}  />
      </div>
      <div className="highlights__competitor--rows-wrapper">
        <Competitor {...competitor1} addedAt={addedAt} participant={participant} type={type} />
        <Competitor {...competitor2} addedAt={addedAt} participant={participant} type={type} />
      </div>
    </div>
  )
}

export default HighlightRow;
import {MatchTickerHighLight} from "modules/InfoScreens/types";

let highlightsLatest: {
  highlights: (MatchTickerHighLight)[]
}  | undefined;

export default {
  rollout(highlights: (MatchTickerHighLight)[]) {
    highlightsLatest = { highlights };
  },

  consume() {
    return highlightsLatest;
  },

  invalidate() {
    highlightsLatest = undefined;
  },

  invalidateAfter(periodMs: number) {
    setTimeout(() => {
      highlightsLatest = undefined;
    }, periodMs);
  }
}

import React from "react";
import "./IframeHeader.scss";

function TennisTimelineHeader() {
  return (
    <div className={"iframe-header-box"}>
      <img className="header-icon timeline-icon" />
      <div className={"statistics-text"}>Satz-Statistiken</div>
    </div>
  );
}

export default TennisTimelineHeader;

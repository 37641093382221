import {http} from "../events/eventsNetwork";
import dynamicSettings from "../dynamicSettings";
import {AxiosResponse} from "axios";
import {MatchTickerHighLight} from "../../modules/InfoScreens/types";
import {config} from "../index";

const highlightsNetwork = {
  async pull(endpoint: string) {
    return http.get(endpoint, {params: {licenseId:  dynamicSettings.getLicense()}});
  },
  generateEndpoint(endpointName: string) {
    switch (endpointName) {
      case "highlights":
        const license = dynamicSettings.getLicense();
        if(license === 'update-license') {
          throw new Error('License is invalid. Generate a license first');
        }
        return config.getEndpoint("highlights");
      default:
        return "";
    }
  },
  async retrieveHighlights(): Promise<MatchTickerHighLight[]> {
    const highlightsEndpoint = this.generateEndpoint("highlights");
    const pull: AxiosResponse<{eventPoints: Array<MatchTickerHighLight>}> = await this.pull(highlightsEndpoint);
    return pull.data.eventPoints;
  }
}

export default highlightsNetwork;
import {useEffect} from "react";

type BarcodeScannerProps = {
  returnValue: (e: string, s: "CC" | "BS") => void;
}

const BarcodeScanner = ({returnValue}: BarcodeScannerProps) => {

  const eBetLogin = (login:string, password?:string) => {
    returnValue(login, "CC");
  }

  const eBetTicket = (ticketNo: string, securityCode: string) => {
    returnValue(`${ticketNo}!${securityCode}`, "BS");
  }

  useEffect(() => {
    // @ts-ignore
    window.eBetCustomerCardBarcode = eBetLogin;
    // @ts-ignore
    window.eBetTicket = eBetTicket;
    return () => {
      // @ts-ignore
      window.eBetCustomerCardBarcode = undefined;
      // @ts-ignore
      window.eBetTicket = undefined;

    }
  }, []);

};

export default BarcodeScanner;
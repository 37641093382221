import config from "./configuration";
import dynamicSettings from "./dynamicSettings";
import configuration from "./configuration";


/*
 Generates a specific i18n-key for a betMarket name
 @param {string} origName - original betMarket name
 @param {string} sport
 @return {string}
*/
export const betmarketName = (origName: string, sport: string): string => {
    const leagueSport = config.getViewConfig().sport;
    const specificNames = config.getSpecificSportBetmarketNames(origName);

    if (specificNames?.indexOf(sport) > -1) {
        return `${origName}-addon-${sport}`;
    }

    if (specificNames?.indexOf(leagueSport) > -1) {
        return `${origName}-addon-${leagueSport}`;
    }

    return origName;
}

/*
 Generates 24hrs hr:min string for current locale
 @return {string}
*/
export const timeClock = (): string => {
    const serverTime = dynamicSettings.getServerTime();
    const d = serverTime > 0 ? new Date(serverTime) : new Date();
    return d.toLocaleTimeString([], {
        hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'Europe/Berlin' 
    });
}

/*
 To be consumpted be UI representation of non-standard game part duration
 @return {string} in format bg:{numGameParts}x:{duration(min)}
*/
export const calcNonStandardDuration = (sectionCount: number, duration: number): string => {
    return `bg:${sectionCount}x${Math.round(duration/60)}'`;
}

/*
 is the sport has non-standard game part duration
 @param {string} sport
 @param {number} duration - game part duration
 @return {boolean}
*/
export const isNonStandardDuration = (sport: string, duration: number): boolean => {
    const configuredStandardDuration = config.getStandardSectionDuration(sport);
    return configuredStandardDuration && configuredStandardDuration !== duration;
}

/*
 make an object values of type string to standard style
 @param {Record<string, any>} obj - an object received from API
 @return {Record<string, any>} - lower cased values
*/
export const standardizeObj = (obj: Record<string, any>): Record<string, any> => {
    return Object.keys(obj).reduce((acc, k: keyof typeof obj) => {
        acc[k] = typeof obj[k] === 'string'
            ? obj[k]?.toLowerCase()
            : obj[k];

        return acc;
    }, {} as Record<string, any>);
}

/*
 pad a value, time with zero
*/
export const pad = {
    /*
     @param {string} int - an integer string
    */
    int: (int: string): string => {
        const num = parseFloat(int);
        if (num > 0 && num < 10 && (num ^ 0) === num) {
            return `${num}.0`;
        }

        return int;
    },

    /*
     @param {number} n - implied to be a part of Date
    */
    time: (n: number|string): string => `0${n}`.slice(-2)
}

/*
* Get text width as it rendered in styled HTML element
* @param {string} elementId
* @param {string} text
* @return {number}
*/
export const getTextWidth = (text: string, elementId = 'technical-span'): number => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const node = document.getElementById(elementId)!;
    node.textContent = text;
    const textWidth = node.offsetWidth;
    node.textContent = '';

    return textWidth;
}

/*
* Verify all the provided arguments are integers
* @param {Array<unknown>} args
* @return {boolean}
*/
export function verifyIntegers(...args: unknown[]) {
    return args.every(arg => Number.isInteger(arg));
}

/*
* Postpone code execution inside async function
*/
export function sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getLocaledString(value: number, isCurrency?: boolean): string {
    const activeLanguage = configuration.getLanguage();
    return value.toLocaleString(activeLanguage, isCurrency ? { style: "currency", currency: "EUR" } : {})
}
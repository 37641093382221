import {MatchTickerHighLight} from "../../modules/InfoScreens/types";

type ProcessHighlightTime = Pick<MatchTickerHighLight, "relativeTime" | "additionalTime" | "sectionEndRelativeTime" | "sectionType">;
type HtTime = {time: number | null, text: string};
export default {
  processHighlightTime({relativeTime, additionalTime, sectionEndRelativeTime, sectionType}: ProcessHighlightTime): HtTime {
    const rt = Math.ceil(relativeTime/60);
    switch (sectionType) {
      case "OVERTIME":
        return {
          time: rt,
          text: additionalTime ? "Verl. +" : "Verl."
        };
      case "REGULAR":
        return {
          time: rt,
          text: additionalTime ? `${sectionEndRelativeTime/60}' +` : ""
        };
      case "PENALTIES":
        return {
          time: -1,
          text: "Penalt."
        }
      default:
        return {
          time: null,
          text: ""
        }
    }
  }
}
import React, {FC, ReactElement, useEffect, useState} from "react";
import Soccer from "./Soccer";
import {MatchTickerHighLight} from "../../types";
import RedCard from "./RedCard";
import classNames from "classnames";
import configuration from "../../../../services/configuration";
import time from "../../../../services/highlights/time";
import Penalty from "./Penlaty";

type TimeBoxProps = Pick<MatchTickerHighLight, "type" | "index" | "addedAt" | "relativeTime" | "additionalTime" | "sectionType" | "sectionEndRelativeTime">;

const TimeBox: FC<TimeBoxProps> = (
  {
    type,
    addedAt,
    relativeTime,
    additionalTime,
    sectionEndRelativeTime,
    sectionType
  }) => {

  const rt = time.processHighlightTime({relativeTime, additionalTime, sectionEndRelativeTime, sectionType})
  
  const icon = (type: TimeBoxProps["type"]): ReactElement | undefined => {
    switch (type) {
      case "GOAL":
        return <Soccer height={68} width={68} />
      case "RED_CARD":
        return <RedCard height={71} width={47.5} />
      default:
        void 0;
    }
  }

  const flashIcon = (): boolean => {
    const date = new Date();
    // @ts-ignore
    typeof addedAt === "object" ? void 0 : addedAt = new Date(addedAt);
    if(date?.getTime() && addedAt?.getTime()) {
      return date.getTime() - addedAt?.getTime() <= (configuration.getAnimationInterval()+2000);
    }
    return false;
  }

  const iconClassBlock = {
    flash: flashIcon(),
    icon: true,
    hide: !flashIcon()
  }

  const timeClassBlock = {
    time: true,
    "flash-time": flashIcon(),
    hide: rt.time === -1
  }

  const textClassBlock = {
    "time-desc": true,
    "flash-time": flashIcon()
  }

  const penaltyClassBlock = {
    hide: rt.time !== -1,
    "flash-penalty": flashIcon(),
    "penalty-box": true
  }

  return (
    <div className={"timebox__highlight"}>
      <div className={classNames(iconClassBlock)}>{icon(type)}</div>
      <div className={classNames(timeClassBlock)}>{rt.time}'</div>
      <div className={classNames(penaltyClassBlock)}>
        <Penalty height={42} width={68} />
      </div>
      <div className={classNames(textClassBlock)}>{rt.text}</div>
    </div>
  )
}

export default TimeBox;
import React, { createElement, FC } from 'react';

import BundesligaTeam from "./BundesligaTeam";
import Teams from "./Teams";
import { NormalizedEvents } from "../../types";
import config from "services/configuration";

type TeamsScoreProps = {
    sport: string;
    score: NormalizedEvents['score'];
    competitor1: NormalizedEvents['competitor1'];
    competitor2: NormalizedEvents['competitor2'];
}

const TeamsScore: FC<TeamsScoreProps> = (props) => {
    const { score, competitor1, competitor2 } = props;
    const specialLayout = config.isLeagueSpecialLayout();
    // either AggregateScore or SectionsScore atm for live, and Upcoming
    const scoreElement = createElement(score.component, {...props, score: score.values});

    if (specialLayout) {
        return (
            <>
                <BundesligaTeam team={competitor1} position="left"/>
                {scoreElement}
                <BundesligaTeam team={competitor2} position="right" />
            </>
        )
    }

    return (
        <>
            <Teams competitor1={competitor1} competitor2={competitor2} />
            {scoreElement}
        </>
    )
}

export default TeamsScore;

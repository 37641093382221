import React, { FC } from "react";

import "./EventRowOdds.scss";
import EventRowOddItem from "./EventRowOddItem";
import { NormalizedEvents } from "../../types";

const EventRowOdds: FC<Pick<NormalizedEvents, "odds">> = (
  props: Pick<NormalizedEvents, "odds">
) => {
  const { odds } = props;

  return (
    <>
      {odds.map((odd, idx) => {
        const key = odd.length ? odd.toString() : idx;
        const extraColumn = odd.filter(o => !!o).length === 4;
        const param = odd[0] && odd[0].length === 3 && odd[0][2] === "param";

        return (
          <div
            className="basic-flex lwk-events-col -border-left-000-2"
            key={key}
          >
            <EventRowOddItem
              odd={odd[0]}
              extraColumn={extraColumn}
              param={param}
            />
            <EventRowOddItem odd={odd[1]} extraColumn={extraColumn} />
            <EventRowOddItem odd={odd[2]} extraColumn={extraColumn} />
            {odd[3] && (
              <EventRowOddItem odd={odd[3]} extraColumn={extraColumn} />
            )}
          </div>
        );
      })}
    </>
  );
};

export default EventRowOdds;

import React, {FC, ReactElement, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import PageTitle from "../components/PageTitle/PageTitle";
import dynamicSettings from "../../../services/dynamicSettings";
import config from "../../../services/configuration";
import {MatchTickerHighLight, MatchTickerPage} from "../types";
import WarningContent from "../../common/ErrorReporting/WarningContent";
import HighlightRow from "../components/HighlightRow/HighlightRow";
import classNames from "classnames";
import {childLimit, HighlightsUI, stackLimit} from "../../../services/highlights/highlightsStack";

let highlights: HighlightsUI = {0: []};


const translationsKey = "infoscreens-lwk-headers";

const viewConfig = config.getViewConfig();
const { title, sport = "", theme = "match-ticker"} = viewConfig;
const MatchTicker: FC<MatchTickerPage> = (props): ReactElement => {
  if (props.error.message) {
    throw props.error;
  }
  const t = dynamicSettings.getTranslation(translationsKey);
  const eventsAvailable = props.highlights[0] && props.highlights[0].eventId;

  const [removedHighlights, setRemovedHighlights] = useState<Record<number, MatchTickerHighLight | undefined>>({});
  const [append, setAppend] = useState(true);

  useEffect(() => {
    if(eventsAvailable && append) {
      setAppend(false);
      appendHighlights();
    }
  }, [eventsAvailable]);

  useEffect(() => {
    if(append && eventsAvailable) {
      setAppend(false);
      appendHighlights();
    }
  }, [append]);

  const appendHighlights = async () => {
    for(let i = 0; i < stackLimit; i++) {
      const item = props.highlights[i];
      if(item.eventId) {
        const found = getHighlight(item, highlights);
        if(!found) {
          if(highlights[0][0] && highlights[0][0]?.occurredAt > item.occurredAt) {
            void 0;
          } else {
            await addItem(item);
          }
        }
      }
    }
    setTimeout(() => {
      cleanStack();
    }, 1000);
  }
  const addItem = (item: MatchTickerHighLight) => {
    return new Promise((resolve) => {
      const sCc = highlights;
      setTimeout(async () => {
        item.addedAt = new Date();
        sCc[0] = [item, ...sCc[0]];
        for(let i = 1; i < stackLimit; i++) {
          if(sCc[i] && sCc[i].length >= childLimit) {
            removedHighlights[i] = sCc[i].pop();
          }
          if(sCc[i - 1] && sCc[i - 1].length > childLimit) {
            const removeNextItem = () => new Promise(resolve => {
              setTimeout(() => {
                const lastObject = sCc[i - 1].pop();
                if(lastObject !== undefined) {
                  lastObject.noAnimation = false;
                  removedHighlights[i - 1] = lastObject;
                  if(sCc[i]) {
                    sCc[i].unshift(lastObject);
                  } else {
                    sCc[i] = [lastObject];
                  }
                }
                resolve(true);
              }, 1000)
            });
            await removeNextItem();
          }
        }
        setRemovedHighlights(removedHighlights);
        resolve(true);
      }, 1000);
    })
  }

  const getHighlight = (item: MatchTickerHighLight, sCc: HighlightsUI): MatchTickerHighLight | undefined => {
    for (let i = Object.keys(sCc).length-1; i >= 0; i--) {
      if(sCc[i] && sCc[i].length > 0) {
        for (let j = sCc[i].length-1; j >= 0; j--) {
          if(sCc[i][j].eventId === item.eventId && sCc[i][j].occurredAt === item.occurredAt) {
            return sCc[i][j];
          }
        }
      }
    }
    return undefined;
  }

  const cleanStack = () => {
    const cdcc = JSON.parse(JSON.stringify(highlights));
    // remove highlights that are not in props.highlights
    for(let i = 0; i < Object.keys(highlights).length; i++) {
      const arr = highlights[i];
      let removed = 0;
      for(let k = 0; k < highlights[i].length; k++) {
        const item = arr[k];
        if(item && item.eventId) {
          const found = props.highlights.find(e => e.eventId === item.eventId && e.occurredAt === item.occurredAt);
          if(!found) {
            cdcc[i].splice(k-removed, 1);
            removed++;
          }
        }
      }
    }

    // reshuffle stack after deletion of non existing highlights
    for(let i = 0; i < Object.keys(cdcc).length; i++) {
      for(let k = 0; k < childLimit; k++) {
        if(cdcc[i].length < childLimit && cdcc[i+1] && cdcc[i+1].length > 0) {
          const popped = cdcc[i+1].shift();
          popped.noAnimation = true;
          cdcc[i].push(popped);
        }
      }
    }
    
    // add missing highlights that exist in props.highlights but not in stack
    for(let i = props.highlights.length-1; i >= 0; i--) {
      const item = props.highlights[i];
      if(item.eventId) {
        const found = getHighlight(item, cdcc);
        if(!found) {
          item.noAnimation = true;
          if(cdcc[0].length < 5) {
            cdcc[0].push(item);
          } else {
            cdcc[1].push(item);
          }
        }
      }
    }
    setRemovedHighlights({});
    highlights = cdcc;
    setAppend(true);
  }
  const wrapperClasses = {
    "match-ticker__row--area-wrapper": true,
    "basic-flex": true,
  }

  return (
    <>
      <Helmet>
        <title>{t(title)}</title>
        <meta name="description" content={t(title)} />
      </Helmet>
      <PageTitle
        title={title}
        sport={sport}
        theme={theme}
        warning={false}
        eventsAvailable={eventsAvailable > 0}
      />
      <WarningContent
        bgClass={"match-ticker"}
        infoMain={props.eventsReady && !eventsAvailable ? `events-unavailable-${title}` : ""}
        infoSub={""}
      />
      <div className="match-ticker__row--area-container">
        <div className={classNames(wrapperClasses)}>
          {
            Object.keys(highlights).map((i) => {
              const highlight = highlights[Number(i)];
              const removedRow = removedHighlights[Number(i)];
              return (
                <div className="match-ticker__row--col1-container" key={i}>
                  {
                    highlight.map(item => {
                      return item.index && <HighlightRow removed={false} {...item} key={item.occurredAt+item.eventId+i} />
                    })
                  }
                  {
                    removedRow?.eventId ? <HighlightRow removed={true} {...removedRow} key={removedRow.occurredAt+removedRow.eventId+"removed"} /> : void 0
                  }
                </div>
              )
            })
          }
          {/*fix for opera*/}
          {!highlights[1] ? <div className="match-ticker__row--col1-container"></div> : void 0}
        </div>
      </div>
    </>
  )
}

export default MatchTicker;
import {
    BetMarketEvent,
    competitorTypeSections,
    NormalizedEvents
} from "modules/InfoScreens/types";
import AggregateScore from "modules/InfoScreens/components/EventRowInfo/Score/AggregateScore";
import SectionsScore from "modules/InfoScreens/components/EventRowInfo/Score/SectionsScore";
import Upcoming from "modules/InfoScreens/components/EventRowInfo/Score/Upcoming";
import config from "services/configuration";

export default {
    /*
    * Put together value of the score column
    * @param {BetMarketEvent} event
    * @return {Score}
    */
    processEventScore(event: BetMarketEvent): NormalizedEvents['score'] {
        const { liveStatus, competitor1, competitor2 } = event;

        if (liveStatus === 'upcoming') {
            return this.upcoming();
        }

        if (competitor1.points && competitor2.points) {
            return this.sectionsScore(event);
        }

        return this.aggregateScore(event);
    },

    /*
    * Upcoming event state
    * @return {Score}
    */
    upcoming(): NormalizedEvents['score'] {
        return {
            component: Upcoming,
            values: []
        };
    },

    /*
    * Sections type of event and gameClock
    * @param {BetMarketEvent} event
    */
    sectionsScore(event: BetMarketEvent): NormalizedEvents['score'] {
        const { sport, competitor1, competitor2, gameClock: {activeParticipant} } = event;
        const scoreBySports: {
            top: { [sportName: string]: keyof competitorTypeSections };
            bottom: { [sportName: string]: keyof competitorTypeSections };
        } = config.getSpecialSectionsScoreDataKeys();
        const topScoreBySports = scoreBySports.top;
        const bottomScoreBySports = scoreBySports.bottom;
        const topKey = topScoreBySports[sport] || topScoreBySports.default;
        const bottomKey = bottomScoreBySports[sport] || bottomScoreBySports.default;

        return {
            component: SectionsScore,
            values: [
                this.calcServingParticipant(competitor1.position, competitor2.position, activeParticipant),
                [`${competitor1[topKey]}`, `${competitor2[topKey]}`],
                [`${competitor1[bottomKey]}`, `${competitor2[bottomKey]}`]
            ]
        };
    },

    /*
    * Aggregate type of event and gameClock
    * @param {string} sport
    * @param {competitorTypeSections} competitor1
    * @param {competitorTypeSections} competitor2
    */
    aggregateScore(event: BetMarketEvent): NormalizedEvents['score'] {
        const { sport, competitor1, competitor2, gameClock: {activeParticipant} } = event;
        const scoreBySports: {
            [index: string]: keyof competitorTypeSections;
        } = config.getSpecialAggregateScoreDataKeys();
        const scoreKey = scoreBySports[sport] || scoreBySports.default;

        return {
            component: AggregateScore,
            values: [
                this.calcServingParticipant(competitor1.position, competitor2.position, activeParticipant),
                [`${competitor1[scoreKey]}`, `${competitor2[scoreKey]}`]
            ]
        };
    },

    /*
    * For games like tennis and table tennis calculate a serving participant
    * @param {string} position1 Position of competitor1
    * @param {string} position2 Position of competitor2
    * @param {string} activeCompetitor Position of the serving party
    */
    calcServingParticipant(position1: string, position2: string, activeCompetitor = 'none'): string[] {
        const serves1 = position1?.toLowerCase() === activeCompetitor ? ':serve:' : '';
        const serves2 = position2?.toLowerCase() === activeCompetitor ? ':serve:' : '';

        return [serves1, serves2];
    }
}

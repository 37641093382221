import { GameClockStopWatch } from "modules/InfoScreens/types";
import { calcNonStandardDuration, isNonStandardDuration } from "services/helper";
import dynamicSettings from "services/dynamicSettings";


/*
 A live event stop watch game clock type processing
 football, field hockey, boxing...
 @param {GameClockStopWatch} clock - an object of type GameClock
 @returns {string[]} minutes for start, and a mark to show on UI
*/
const stopWatch = (sport: string, clock: GameClockStopWatch) => {
    const time: string[] = [];

    if (clock.eventState !== 'running') {
        time[0] = clock.eventState;
    } else {
        const serverTime = dynamicSettings.getServerTime();
        const d = serverTime > 0 ? new Date(serverTime) : new Date();
        const utcDiffMs = d.getTime() - parseInt(clock.utcTimestamp, 10);
        const additionalTimeMs = Math.max(
            0,
            utcDiffMs + clock.sectionTime * 1000 - clock.sectionDuration * 1000
        );
        const additionalTimeMin = Math.ceil(additionalTimeMs / 60000);
        const eventTimeMin = Math.ceil(
            (utcDiffMs + clock.eventTime * 1000 - additionalTimeMs) / 60000
        );

        time[0] =
            additionalTimeMs > 0
                ? `${eventTimeMin}' + ${additionalTimeMin}`
                : `${Math.ceil(
                    (utcDiffMs + clock.eventTime * 1000 - additionalTimeMs) / 60000
                )}'`;
    }

    if (clock.sectionType === "overtime") {
        time[1] = "bg:ot";
    } else if (clock.sectionDuration && isNonStandardDuration(sport, clock.sectionDuration)) {
        time[1] = calcNonStandardDuration(clock.regularSectionCount, clock.sectionDuration);
    }

    return time;
}

export default stopWatch;

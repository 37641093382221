import React, {FC} from "react";

type RedCardProps = {
  height: number;
  width: number;
  count?: number;
}

const RedCard: FC<RedCardProps> = ({height, width, count}) => {
  return <div className="red-card" data-testid={"red-card"} style={{height, width}}><span className="red-card-count">{(count && count > 1) && count}</span></div>
}

export default RedCard;
import React, {FC} from "react";
import {BetslipResponse, BetslipSidebarStatuses} from "../../../../services/infoTerminal/infoNetwork";
import dayjs from "dayjs";
import dynamicSettings from "../../../../services/dynamicSettings";
import {getLocaledString} from "../../../../services/helper";

const BetslipSideBar: FC<BetslipResponse> = (props) => {

  const isDeliveredOrCanceled =  props.status === "PRELIMINARY" || props.status === "DELIVERED" || props.status === "CANCELED";
  const t = dynamicSettings.getTranslation("infoterminal");

  return (
    <div className="info-terminal__betslip-sidebar___container">
      { isDeliveredOrCanceled && <Header showIcon={props.status !== "PRELIMINARY"} status={props.status} text={t(`customer-card-betslip-status-${BetslipSidebarStatuses[props.status]}`)} />}
      <div className={`betslip-sidebar__betslip-info____wrapper ${isDeliveredOrCanceled ? "border-bottom-radius" : "border-top-radius"}`}>
        <div className="betslip-sidebar__betslip-info____container">
          {props.number > 0 && <div className="betslip-sidebar__betslip-info___nr">
            {t("number")} <span>{props.number}</span>
          </div>}
          {props.date && <div className="betslip-sidebar__betslip-info___date">
            {dayjs(props.date).format("MMM. DD, YYYY, hh:mm A")}
          </div>}
        </div>
        {props.system && <div className="betslip-sidebar__betslip-info___system">
            <label>{t("bet-system")}</label>
            <span>{props.system}</span>
        </div>}
        {props.oddBoost > 0 && <div className="betslip-sidebar__betslip-info___odd-boost">
            <label>{t("quote-boost")}</label>
            <span>{props.oddBoost} %</span>
        </div>}
        {props.maxCombinedOdds > 0 && <div className="betslip-sidebar__betslip-info___max-odds">
            <label>{t("max-odds")}</label>
            <span className={props.maxCombinedOddsWithOddsBoost > 0 ? "with-odd-booster" : ""}>{getLocaledString(Number(props.maxCombinedOdds.toFixed(2)))}</span>
            <span>{props.maxCombinedOddsWithOddsBoost > 0 && getLocaledString(Number(props.maxCombinedOddsWithOddsBoost.toFixed(2)))}</span>
        </div>}
        {props.stake > 0 && <div className="betslip-sidebar__betslip-info___stake">
            <label>{t("stake")}</label>
            <span>{getLocaledString(props.stake, true)}</span>
        </div>}
        <div className="betslip-sidebar__betslip-info___logo">
          <span/>
        </div>
        {props.maxPrize > 0 && <div className="betslip-sidebar__betslip-info___prize">
          <span>{getLocaledString(props.maxPrize, true)}</span>
          <label>{t("max-total-prize")}</label>
        </div>}
      </div>
      { !isDeliveredOrCanceled && <Footer prize={props.prize} status={props.status} text={t(`customer-card-betslip-status-${BetslipSidebarStatuses[props.status]}`)} /> }
    </div>
  )
}

const Header: FC<{ text: string, status: BetslipResponse["status"], showIcon: boolean }> = (props) => {
  const {text, showIcon} = props;
  return (
    <div className={"betslip-sidbar__header___wrapper " + props.status}>
      {showIcon && <span className="betslip-sidbar__header___icon" />}
      <div>{text}</div>
    </div>
  );
}

const Footer: FC<{ text: string, status: BetslipResponse["status"], prize: BetslipResponse["prize"] }> = (props) => {
  const {text, status, prize} = props;
  return (
    <div className={"betslip-sidbar__footer___wrapper " + status}>
      <div>{text}{(status === "PAYOUT" || status === "PAID_OUT") && `: ${getLocaledString(prize, true)}`}</div>
    </div>
  )
}



export default BetslipSideBar;
import { useEffect } from "react";

import eventsService from "services/events";
import {PullEventsCB} from "../../InfoScreens/types";
import loadTranslation from "services/translation";
import config from "services/configuration";
import highlightsService from "../../../services/highlights";

const pullInterval = config.getPullingInterval();
const mockResponseHeaders = {
    "total-pages": "1"
}
let n = 0;

const useMock = (cb: PullEventsCB, isHighlights?: boolean) => {
    useEffect(() => {
        function getEvents() {
            if(!isHighlights) {
                n = n >= 10 ? 1 : ++n; // nosonar
                try {
                    const json = require(`../../../services/__mock__/events9.json`);
                    eventsService.refresh(mockResponseHeaders);
                    const events = eventsService.normalizer(json);

                    cb(new Error(), { ...events, eventsReady: true });
                } catch (e: any) {
                    cb(e);
                }
            } else {
                n = n >= 11 ? 11 : ++n;
                try {
                    const json = require(`../../../services/__mock__/eventsMT${n}.json`);
                    highlightsService.refresh();
                    const highlights = highlightsService.normalizer(json.eventPoints);
                    // @ts-ignore
                    cb(new Error(), { highlights: highlights, eventsReady: true, translationsReady: true });
                } catch (e: any) {
                    cb(e);
                }

            }

        }

        loadTranslation("infoscreens").then(() => {
            cb(new Error(),{ translationsReady: true });
        });

        const id = window.setInterval(getEvents, pullInterval);
        setTimeout(() => window.clearInterval(id), 50000);

        return () => window.clearInterval(id);
    }, []);
}

export default useMock;

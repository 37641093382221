import React, { FC } from "react";

import Generic from "../EventRowInfo/Generic";

export const EventRowSpecialEvent: FC<{
  comment: string;
  show: boolean;
}> = props => {
  if (!props.show) {
    return <></>;
  }

  const { comment } = props;

  return (
    <div className="basic-flex events__data--row -border-top-000-2">
        <Generic content="→" />
      <div className="basic-flex lwk-events--comments-col">
        <div className="events__comment -border-left-000-2">{comment}</div>
      </div>
    </div>
  );
};

export default EventRowSpecialEvent;

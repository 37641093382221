import React, { FC } from 'react';
import NotRunning from "./NotRunning";
import StopWatchAndUpcomingTime from "./StopWatchAndUpcomingTime";
import ProgressTime from "./ProgressTime";
import SectionsTime from "./SectionsTime";
import Pause from "./Pause";

interface EventRowTimeProps {
    type: string;
    state: string;
    time: string[];
    techDisplayName: string;
    sectionType: string;
}

const Time: FC<EventRowTimeProps> = ({type, state, time, techDisplayName, sectionType}) => {
    if(state === "break") {
        if(techDisplayName === "ft" && sectionType === "regular") {
            return <Pause state={"beforeET"} />;
        }
        if(techDisplayName === "ht" && sectionType === "overtime") {
            return <Pause state={"betweenET"} />;
        }
        if(techDisplayName === "ft" && sectionType === "overtime") {
            return <Pause state={"beforePenalty"} />;
        }
    }

    if (type === 'stopwatch' || type === 'upcoming') {
        return <StopWatchAndUpcomingTime time={time} />;
    }

    if (state !== 'running') {
        return <NotRunning state={time[0]} />;
    }

    if (type === 'progress') {
        return <ProgressTime time={time} />;
    }

    if (type === 'sections') {
        return <SectionsTime time={time} />;
    }

    return <NotRunning state={time[0]} />;
};

export default Time;

import config from "../services/configuration";

type PropEvType = (prop: string) => string;

const dynamicSettings = () => {
  const settings = {
    translations: {} as Record<string, PropEvType>,
    license: config.getUpdateLicenseName(),
    totalPages: "",
    scoreColWidth: 0,
    serverTime: 0,
    valuesMovement: new Map() as Map<string, string>,
    transportStatus: "ready"
  }

  const setTranslation = (key: string, translation: PropEvType): void  => {
    settings.translations[key] = translation;
  }

  const getTranslation = (key: string): PropEvType => {
    return settings.translations[key];
  }

  const setTransportStatus = (status: string) => {
    settings.transportStatus = status;
  }

  const getTransportStatus = () => {
    return settings.transportStatus;
  }

  const setLicense = (license: string): void => {
    settings.license = license;
  }

  const getLicense = (): string => {
    return settings.license;
  }

  const getTotalPages = (): string => {
    return settings.totalPages;
  }

  const setTotalPages = (total: string | number): void => {
    settings.totalPages = String(total);
  }

  const setScoreColWidth = (width: number) => {
    settings.scoreColWidth = width;
  }

  const getScoreColWidth = () => {
    return settings.scoreColWidth;
  }

  const setEventValueMovement = (valueId: string, status: string) => {
    settings.valuesMovement.set(valueId, status);
  }

  const getEventValueMovement = (valueId: string) => {
    return settings.valuesMovement.get(valueId) || "";
  }

  const flashValuesMovement = () => {
    settings.valuesMovement = new Map();
  }

  const setServerTime = (serverTime: string | number): void => {
    settings.serverTime = Number(serverTime);
  }

  const getServerTime = (): number => {
    return settings.serverTime;
  }

  return {
    setTranslation,
    setLicense,
    setTotalPages,
    setScoreColWidth,
    setEventValueMovement,
    setTransportStatus,
    getTranslation,
    getLicense,
    getTotalPages,
    getScoreColWidth,
    getEventValueMovement,
    getTransportStatus,
    flashValuesMovement,
    setServerTime,
    getServerTime
  }
};

export default dynamicSettings();
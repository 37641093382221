import React from "react";
import TimelineHeader from "./TimelineHeader";
import TennisTimelineHeader from "./TennisTimelineHeader";

import { SportEvent as SoccerSportEvent } from "../ScoreCenter/Soccer/SoccerLive/model";
import { SportEvent as TennisSportEvent } from "../ScoreCenter/Tennis/TennisLive/model";

interface Props {
  scoreCenterData: SoccerSportEvent | TennisSportEvent;
}

function IframeHeader(props: Props) {
  return props.scoreCenterData.sportId === "soccer" ? (
    <TimelineHeader />
  ) : (
    <TennisTimelineHeader />
  );
}

export default IframeHeader;

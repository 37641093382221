import React, { FC } from "react";
import BarcodeScanner from "../components/BarcodeScanner";
import { useHistory, useLocation } from "react-router-dom";

const InfoTerminal: FC = () => {
  const location = useLocation();
  const history = useHistory();

  BarcodeScanner({
    returnValue: (barcode, type) => {
      if (type === "CC") {
        history.push(
          `/info-terminal/customer-card/${barcode}${location.search}`
        );
      }
      if (type === "BS") {
        history.push(`/info-terminal/betslip/${barcode}${location.search}`);
      }
    },
  });

  return <div className="info-terminal-base__wrapper" />;
};

export default InfoTerminal;

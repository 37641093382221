import React, { FC } from "react";
import classnames from "classnames";

import dynamicSettings from "services/dynamicSettings";

const EventRowOddItem: FC<{
  odd: (string | number)[];
  extraColumn: boolean;
  param?: boolean;
}> = (props: {
  odd: (string | number)[];
  extraColumn: boolean;
  param?: boolean;
}) => {
  const mainClass = "coeff-explain-val";

  if (!props.odd || !props.odd[1]) {
    return <div className={mainClass} />;
  }

  const { odd, extraColumn, param } = props;
  const oddMoveClass = dynamicSettings.getEventValueMovement(`${odd[0]}`);

  const css = classnames(mainClass, {
    [oddMoveClass]: oddMoveClass,
    "odds": true,
    "extra-column": extraColumn,
    "extra-column-value": param,
    "-f-26": `${odd[1]}`.length > 6
  });

  return (
    <>
      <div className={css}>
        <span>{odd[1]}</span>
      </div>
    </>
  );
};

export default EventRowOddItem;

import React from "react";
import "./Header.scss";
import Title from "../Title/Title";

function Header() {
  return (
    <div className="pitch-animation-header">
      <Title />
    </div>
  );
}

export default Header;

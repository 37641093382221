import React, { FC } from 'react';
import classNames from "classnames";

import Serve from "./Serve";
import { classes } from "modules/InfoScreens/components/EventRow/EventRow";
import { competitorTypeSections, NormalizedEvents } from "modules/InfoScreens/types";
import dynamicSettings from "services/dynamicSettings";

interface AggregateScoreProps {
  sport: string;
  score: NormalizedEvents['score']['values'];
  competitor1: competitorTypeSections;
  competitor2: competitorTypeSections;
}

const AggregateScore: FC<AggregateScoreProps> = ({ sport, score, competitor1, competitor2}) => {
  const scoreColWidth = dynamicSettings.getScoreColWidth();
  const scoreClass1 = { className: dynamicSettings.getEventValueMovement(`${competitor1.id}`)}
  const scoreClass2 = { className: dynamicSettings.getEventValueMovement(`${competitor2.id}`)}
  const hasFullTimeScore = competitor1.fullTimeScore !== undefined;
  const classesWrapper = classNames({
    [classes.basicFlex]: true,
    [classes.lwkEventsCol]: true,
    [classes.vertFlex]: true,
    [classes.borderLeft]: true,
    [classes.relative]: true
  });
  const classesGoals = classNames({
    [classes.yellowClass]: true,
    [classes.lineHeight60]: competitor1.fullTimeScore === undefined,
    [classes.score]: true
  });
  const classesFTGoals = classNames({
    [classes.fullTimeScore]: true
  });

  const noLineHeight = hasFullTimeScore ? {
    lineHeight: 0
  } : void 0;

  return (
      <div className={classesWrapper} style={{width: `${scoreColWidth}px`, ...noLineHeight}}>
        <div className={classesGoals} style={hasFullTimeScore ? {lineHeight: "35px"} : {}}>
          <Serve sport={sport} score={score[0]} />
          <span {...scoreClass1}>{score[1][0]}</span>
          :
          <span {...scoreClass2}>{score[1][1]}</span>
        </div>
        {hasFullTimeScore && <div className={classesFTGoals}>
          (<span style={noLineHeight}>{competitor1.fullTimeScore}</span>
          :
          <span style={noLineHeight}>{competitor2.fullTimeScore}</span>)
        </div>}
      </div>
  );
};

export default AggregateScore;

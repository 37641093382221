import React, { FC } from "react";
import { Helmet } from "react-helmet";

import Header from "../components/Header/Header";
import PitchAnimationRestService from "../components/PitchAnimation/PitchAnimationRestService";
import { config } from "../../../services";

import "./PitchAnimation.scss";

const PitchAnimation: FC = () => {
  const language = config.getLanguage();

  return (
    <div className="pitch-animation-screen">
      <Helmet>
        <title>Pitch Animation</title>
        <meta name="description" content="Pitch animation infoscreen" />
      </Helmet>
      <Header />
      <PitchAnimationRestService
        sportId={"soccer"}
        language={language}
        className="left-pitch-animation-box"
      />
      <PitchAnimationRestService
        sportId={"tennis"}
        language={language}
        className="right-pitch-animation-box"
      />
    </div>
  );
};

export default PitchAnimation;

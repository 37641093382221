import React, { FC } from "react";

import EventRowOdds from "../EventRowOdds/EventRowOdds";
import Generic from "../EventRowInfo/Generic";
import { specialEventType } from "../../types";
import dynamicSettings from "services/dynamicSettings";

const translationsKey = "infoscreens-lwk-event";
const blackBorderLeftClass = "-border-left-000-2";

export const EventRowSpecialEvent: FC<{
  specialEvent: specialEventType | undefined;
  show: boolean;
}> = props => {
  if (!props.show || !props.specialEvent) {
    return <></>;
  }

  const { caption1, caption2, odds } = props.specialEvent;
  const t = dynamicSettings.getTranslation(translationsKey);

  return (
    <div className="basic-flex events__data--row -border-top-000-2">
      {[
        {
          content: "→"
        },
        {
          border: blackBorderLeftClass,
          nobody: true
        }
      ].map((eventProps, idx) => (
        <Generic {...eventProps} key={eventProps.content || idx} />
      ))}
      <div className="basic-flex lwk-events-col -specialbet">
        {[
          {
            teams: true,
            border: blackBorderLeftClass,
            content: t(caption1)
          },
          {
            teams: true,
            border: blackBorderLeftClass,
            content: `→ ${t(caption2)}`
          }
        ].map((eventProps, idx) => (
          <Generic {...eventProps} key={eventProps.content || idx} />
        ))}
      </div>
      <EventRowOdds odds={odds} />
    </div>
  );
};

export default EventRowSpecialEvent;

import React from "react";
import { useTranslation } from "react-i18next";

export interface TimeLineProps {
  team: string;
}
export interface TimeLinePropsWithScore extends TimeLineProps {
  score: string;
}
export interface TimeLinePropsWithClass extends TimeLineProps {
  className: string;
}
export interface TimeLinePropsWithClassAndScore extends TimeLinePropsWithScore {
  className: string;
}

export function TimeLineDivItem(props: TimeLinePropsWithClass) {
  const { t } = useTranslation("timeline");
  return <div className={props.className}> {t(props.team)} </div>;
}

export function TimeLineDivItemWithScore(
  props: TimeLinePropsWithClassAndScore
) {
  const { t } = useTranslation("timeline");
  return (
    <div className={props.className}>
      {t(props.team)} ({props.score})
    </div>
  );
}

export function TimeLineSpanItem(props: TimeLinePropsWithScore) {
  const { t } = useTranslation("timeline");
  return (
    <span>
      {t(props.team)} ({props.score})
    </span>
  );
}

import React from "react";
import "./Title.scss";

function Title() {
  return (
    <div>
      <img className="top-left-logo" />
      <div className="pitch-animation-title">Live Statistiken</div>
      <div className="pitch-animation-text">
        Nutzen Sie unseren neuen Service um Ihr Event live zu verfolgen. Alle
        Statistiken und Aktionen auf einen Blick.
      </div>
    </div>
  );
}

export default Title;

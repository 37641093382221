import config from "./configuration";
import dynamicSettings from "./dynamicSettings";

const translationsFolder = "translations";

const i18nProxy = (langTra: Record<string, string>, defaultTra: Record<string, string> = {}) => {
    return (prop: string) => {
        if (prop in langTra) {
            return langTra[prop];
        }

        if (prop in defaultTra) {
            return defaultTra[prop];
        }

        const propAllSports = prop?.replace(/-addon-(.*)$/, '');
        if (propAllSports in langTra) {
            return langTra[propAllSports];
        }

        return (
            defaultTra[propAllSports] ||
            prop?.
                replace(/[^a-z\\. \\+':0-9]+/gi, " ")
                .replace(/[ ]+/gi, " ")
                .replace(/(?:^|\s)\S/g, (a: string) => a.toUpperCase())
        );
    }
};

export const _testI18nProxy = () => {
    if (process.env.NODE_ENV === 'test') {
        return i18nProxy;
    }
    return null;
}

// let the function accept @param type: string,
// and filter translations for each specific type
const translation = (type: string) =>
  new Promise(res => {
    const translations = config.getTranslations();
    const language = config.getLanguage();
    const rex = `^${type}`;
    const tSort = (q: string) => new RegExp(rex, "i").test(q);

    Object.keys(translations)
      .filter(tSort)
      .forEach(key => {
        const modulePath = config.getTranslationsModule(key);

        const defaultTranslations: Record<
          string,
          string
        > = require(`@/${modulePath}/${translationsFolder}/default.json`);

        try {
          const langTranslations = require(`@/${modulePath}/${translationsFolder}/${language}.json`);

          dynamicSettings.setTranslation(
            key,
            i18nProxy(langTranslations, defaultTranslations)
          );
        } catch (err) {
          console.log(err);
          dynamicSettings.setTranslation(key, i18nProxy(defaultTranslations));
        }
      });

    res(true);
  });

export default translation;

import React, {FC, useRef, useState} from "react";
import {competitorTypeMT, MatchTickerHighLight} from "../../types";
import RedCard from "./RedCard";
import classNames from "classnames";
import Soccer from "./Soccer";
import configuration from "../../../../services/configuration";

type CompetitorType = Pick<MatchTickerHighLight, "participant" | "type" | "addedAt"> & competitorTypeMT;

const Competitor: FC<CompetitorType> = (props) => {
  const teamIcon = <span
    className={`-icon-team-flag --team${props.id}`}
  />;
  const fallBackIcon = <span
    className={"-icon-team-flag-fallback"}
  />;

  const isUpdated = props.participant === props.position;

  const showRedCard = () => {
    if(props.participant === props.position && props.type === "RED_CARD" && props.aggregatedPoints.RED_CARD && props.aggregatedPoints.RED_CARD > 0) return <div className="col"><RedCard count={props.aggregatedPoints.RED_CARD} height={40} width={27}/></div>
  }
  const showFootball = () => {
    if(props.type === "GOAL" && props.participant === props.position) return <div className="col"><Soccer height={44} width={44}/></div>;
  }

  const showGoalBox = (): boolean => {
    const date = new Date();
    let aa = props.addedAt;
    // @ts-ignore
    typeof aa === "object" ? void 0 : aa = new Date(aa);
    if(date?.getTime() && aa?.getTime()) {
      return (date.getTime() - aa?.getTime() <= (configuration.getAnimationInterval()+2000)) && props.type === "GOAL" && props.participant === props.position;
    }
    return false;
  }

  const nameClassBlock = {
    "isUpdated": isUpdated,
    "team-name-col": true
  };

  const scoreClassBlock = {
    goal: showGoalBox(),
    col: true,
    "score-col": "true"
  }
  return (
    <div className="basic-flex highlights__competitor--row-main">
      <div className="basic-flex highlights__competitor--row-wrapper">
        <div className="col">
          <div className="fallback-icon">{fallBackIcon}</div>
          <div className="team-icon">{teamIcon}</div>
        </div>
        <div className={classNames(nameClassBlock)}>{props.name}</div>
        {showRedCard()}
        {showFootball()}
      </div>
      <div className="highlights__competitor--score-wrapper">
        <div className={classNames(scoreClassBlock)}>{props.score}</div>
      </div>
    </div>
  )
}

export default Competitor;
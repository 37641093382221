import React, { FC } from 'react';
import classNames from "classnames";

import { competitorAggregatedPoints, competitorType } from "../../types";
import { competitorEventPoints } from "./Teams";
import dynamicSettings from "services/dynamicSettings";

interface BundesligaTeamProps {
    team: competitorType;
    position: "left" | "right"
}

const BundesligaTeam: FC<BundesligaTeamProps> = ({team, position}) => {
    const classesBlock = {
        "events__team": true,
        "events__team__bundesliga": true,
        "-border-left-000-2": true
    };
    const teamPoints = competitorEventPoints(team.aggregatedPoints, position);
    const teamIcon = <span
        className={`-icon-team-flag --team${team.id}`}
    />;

    return (
        <div className={classNames(classesBlock)}>
            {position === "left" ? teamPoints : teamIcon}
            <span className="bundesleague-team">{team.name}</span>
            {position === "left" ? teamIcon : teamPoints}
        </div>
    );
};

export default BundesligaTeam;

import React, { FC } from 'react';
import classNames from "classnames";

import dynamicSettings from "../../../../../services/dynamicSettings";
import { translationsKey, classes } from "../../EventRow/EventRow";

interface StopWatchTimeProps {
    time: string[];
}

const StopWatchAndUpcomingTime: FC<StopWatchTimeProps> = ({time}) => {
    const timeSplit = time.length > 1;
    const t = dynamicSettings.getTranslation(translationsKey);
    const contentTop = /^-icon/.test(time[0])
        ? <span className={time[0]} />
        : t(time[0]);
    const contentBottom = timeSplit ? t(time[1].replace(/^bg:/, '')) : "";

    const classesWrapper = classNames({
        [classes.basicFlex]: true,
        [classes.lwkEventsCol]: true,
        [classes.vertFlex]: true,
        [classes.borderLeft]: true
    });
    const classesTop = classNames({
        [classes.yellowClass]: true,
        [classes.vertSplitClass]: timeSplit,
        [classes.textOverflow]: timeSplit
    });
    const classesBottom = classNames({
        [classes.vertSplitClass]: true,
        [classes.bg.custom]: true,
        [classes.bg.grey45]: /^bg/.test(time[1])
    });

    return (
        <div className={classesWrapper}>
            <span className={classesTop}>{contentTop}</span>
            <span className={classesBottom}>{contentBottom}</span>
        </div>
    );
}

export default StopWatchAndUpcomingTime;

import React, { useRef, useEffect, FC } from "react";
// @ts-ignore
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";

import { timeClock } from "services/helper";

interface FlipClockProps {
    isMatchTicker?: boolean;
}
const FlipClock: FC<FlipClockProps> = ({isMatchTicker}) => {
    const _tickRef = useRef(null);
    let tickInstance = {} as typeof Tick;

    useEffect(() => {
        tickInstance = Tick.DOM.create(_tickRef.current, {
            value: timeClock()
        });

        const sid = setInterval(() => {
            tickInstance.value = timeClock();
        }, 1000);

        return () => {
            clearInterval(sid);
            Tick.DOM.destroy(_tickRef.current);
        }
    }, []);

    return (
        <div ref={_tickRef} className={"tick " + (isMatchTicker ? "match-ticker" : "")} data-value='00:00'>
            <div aria-hidden="true">
                <span data-view="flip" />
            </div>
        </div>
    )
}

export default FlipClock;

import React, { FC, memo } from "react";
import {Route, Switch} from "react-router-dom";
import BetslipSideBar from "../components/sidebars/BetslipSideBar";
import CustomerCardSideBar from "../components/sidebars/CustomerCardSideBar";
import {BetslipResponse, CustomerCardResponse} from "../../../services/infoTerminal/infoNetwork";

const InfoRouter: FC<(BetslipResponse | CustomerCardResponse) & { page: number }> = (props) => {
  return (
    <Switch>
      <Route path="/info-terminal/betslip"><BetslipSideBar {...props as BetslipResponse} /></Route>
      <Route path="/info-terminal/customer-card"><CustomerCardSideBar page={props.page} {...props as CustomerCardResponse} /></Route>
    </Switch>
  );
};

export default memo(InfoRouter);

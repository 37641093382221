import React, {useCallback, useEffect, useState} from "react";
import {MatchTickerContainerState, PullMTEventsCB} from "../types";
import config from "../../../services/configuration";
import useMock from "../../common/hooks/useMock";
import ErrorBoundaries from "../../common/ErrorReporting/ErrorBoundaries";
import MatchTicker from "../pages/MatchTicker";
import useHighlights from "../../common/hooks/useHighlights";
import {pingGoogle} from "../../../services";
import {resetServicesOnError} from "../../common/hooks/useEvents";
import dynamicSettings from "../../../services/dynamicSettings";

let lastStatePayload: Partial<MatchTickerContainerState> | undefined;
let showingError = false;
const MatchTickerContainer = (props: { errorResetKey: number }) => {
  const [state, setState] = useState<MatchTickerContainerState>({
    highlights: [],
    translationsReady: false,
    eventsReady: false,
    error: new Error()
  });

  let _isMounted = false;

  useEffect(() => {
    _isMounted = true;
    return () => {
      _isMounted = false;
    }
  }, [])

  const checkNetwork = () => {
    pingGoogle().then(isStable => {
      if(!isStable) {
        showingError = true;
        return setState({
          ...state,
          error: new Error('network error')
        });
      } else {
        showingError = true;
        return setState({
          ...state,
          error: new Error("404")
        })
      }
    })
  }

  const cb: PullMTEventsCB = useCallback((error, statePayload?: Partial<MatchTickerContainerState>) => {
    if(statePayload?.highlights && statePayload?.highlights[0]) {
      lastStatePayload = statePayload;
    }
    if (error.message && _isMounted) {
      if(!showingError) {
        // if there is no old value and the error appears, check network and show error right away
        if(!lastStatePayload) {
          checkNetwork();
        }
        const timeout = setTimeout(() => {
          if(lastStatePayload && lastStatePayload?.highlights) {
            clearInterval(interval);
            checkNetwork();
          }
        }, config.getErrorInterval());
        const interval = setInterval(() => {
          if(!showingError) {
            pingGoogle().then(isStable => {
              if(isStable) {
                clearTimeout(timeout);
                clearInterval(interval);
                resetServicesOnError();
                dynamicSettings.setTransportStatus("ready");
                return setState({
                  ...state,
                  ...lastStatePayload,
                  error: new Error()
                })
              }
            })
          }
        }, 1500);
      }
      return;
    }
    if(_isMounted) {
      showingError = false;
      return setState({
        ...state,
        ...statePayload,
        error: new Error()
      });
    }
  }, []);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  config.useMockData() ? useMock(cb, true) : useHighlights(cb);

  return (
    <ErrorBoundaries
      errorHandler="page"
      key={state.error.message || "noErrorKey"}
    >
      <MatchTicker {...props} {...state} />
    </ErrorBoundaries>
  )
}

export default MatchTickerContainer;
import React, { FC } from 'react';
import classNames from "classnames";

import "./Serve.scss";

interface ServeProps {
    sport: string;
    score: string[];
}

const Serve: FC<ServeProps> = ({sport, score}) => {
    if (!score[0] && !score[1]) {
        return null;
    }

    const side = score[0] && !score[1] ? 'left' : 'right';

    const classes = classNames({
        [`-icon-${sport}-serve`]: true,
        '-icon-serve': true,
        [`--icon-${sport}-serve__${side}`]: true
    });

    return <span className={classes} />;
};

export default Serve;

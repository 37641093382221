import config from "../configuration";
import dynamicSettings from "../dynamicSettings";
import stack from "./eventsStack";
import time from "./time";
import score from "./score";
import { pad, standardizeObj, verifyIntegers } from "../helper";
import {
  BetMarketHeader,
  BetMarketEvent,
  betMarketType,
  InfoScreenNormalized,
  NormalizedEvents,
  oddType,
  competitorType,
  specialBetType,
  specialEventType,
  competitorAggregatedPoints,
  competitorTypeSections
} from "modules/InfoScreens/types";

const events = {
  /*
  Protect odd from type errors in components
  @param {oddType} odd - a single odd
  */
  protectOdd(odd: oddType) {
    if (odd?.type.toLowerCase() === "param") {
      return {
        id: "",
        name: "",
        value: pad.int(odd.param || ""),
        type: "param"
      };
    }

    if (!odd?.id || !odd?.value || parseFloat(odd.value) <= 0) {
      return {
        id: odd?.id || "",
        name: "",
        value: "",
        type: "odd"
      };
    }

    return { ...odd, value: pad.int(`${odd.value}`) };
  },

  collectOdd(odd: oddType) {
    if (!odd.id || odd.type === "PARAM") {
      return odd;
    }

    stack.saveOddMove(odd);
    return odd;
  },

  /*
  Fill Odds/headers array up to the config.totalBetMarketsOnPage
  @param {any[]} row - an array to process
  */
  fillToTotalBetMarkets(row: any[]) {
    const totalBetMarkets = config.getTotalBetMarketsOnPage();
    if (row.length < totalBetMarkets) {
      const originalLength = row.length;
      row.length = totalBetMarkets;
      row.fill([], originalLength, totalBetMarkets);
    }

    return row;
  },

  /*
  Prepare header to show up on a page
  @param {BetMarketHeader} header - raw header of the follow up events
  */
  processHeader(header: BetMarketHeader): BetMarketHeader {
    header = standardizeObj(header) as BetMarketHeader;
    header.betMarketHeaders = this.fillToTotalBetMarkets(
      header.betMarketHeaders
    );

    return header;
  },

  processEventCode(hex: string, dec: number): string {
    return config.getNoFormat() === 'dec'
        ? `${dec}`
        : hex;
  },

  /*
  Prepare event to show up on a page as a single row
  @param {BetMarketEvent} event - raw event in sequence received from PDS
  */
  processEvent(event: BetMarketEvent) {
    const eventId = `${event.id}`;
    event.sport = event.sport.toLowerCase();
    event.liveStatus = event.liveStatus.toLowerCase();
    event.gameClock = standardizeObj(event.gameClock) as BetMarketEvent['gameClock'];
    stack.initEvent(event.sport, eventId);

    const eventCode = this.processEventCode(event.code, event.decimalCode);
    const eventCompetitor1 = this.processEventCompetitor(event.competitor1);
    const eventCompetitor2 = this.processEventCompetitor(event.competitor2);
    this.calcScoreColumnWidth(eventCompetitor1, eventCompetitor2, event.gameClock.clockType);
    const eventTime = time.processEventTime(event);
    const eventOdds = this.processEventBetMarketOdds(event.betMarkets);
    const eventLiveComment = event.liveComment || "";
    const eventSpecialBet =
      event.specialBet && this.processEventSpecialBet(event.specialBet);
    const eventScore = score.processEventScore(event);

    return stack.addToStack({
      id: eventId,
      code: eventCode,
      sport: event.sport,
      liveStatus: event.liveStatus,
      name: event.name, // not essential, consider to remove or use for logging
      type: "EVENT",
      onTop: event.onTop,
      time: eventTime,
      competitor1: eventCompetitor1,
      competitor2: eventCompetitor2,
      score: eventScore,
      odds: eventOdds,
      liveComment: eventLiveComment,
      specialEvent: eventSpecialBet
    } as NormalizedEvents);
  },

  /*
  Put together normalized bet markets odds
  @param {betMarketOdds} betMarketOdds - raw odds received from PDS
  */
  processEventBetMarketOdds(betMarketOdds: betMarketType[]) {
    const normalizedOdds = betMarketOdds.map(({ odds: bmOdds }) => {
      // a column can have empty odds list
      if (!bmOdds.length) {
        return [];
      }
      // extract odds
      let [a, b, c, d = null] = bmOdds;

      a = this.collectOdd(this.protectOdd(a));
      b = this.collectOdd(this.protectOdd(b));
      c = c && this.collectOdd(this.protectOdd(c));
      d = d && this.collectOdd(this.protectOdd(d));

      // put together normalized odds
      return [
        [a.id, a.value, a.type],
        [b.id, b.value],
        c && [c.id, c.value],
        d && [d.id, d.value]
      ];
    });

    return this.fillToTotalBetMarkets(normalizedOdds);
  },

  /*
  An event competitor processing
  @param {competitor} competitorType - object of id, name, and eventPoints (for now, only red cards)
  @return {competitorType}
  */
  processEventCompetitor(competitor: competitorType): competitorType {
    const points =
      !!competitor.aggregatedPoints &&
      Object.keys(competitor.aggregatedPoints).reduce(
        (agg: competitorAggregatedPoints, key) => {
          const lowerKey = key.toLowerCase();
          const value =
            competitor.aggregatedPoints[key as keyof typeof agg] || 0;
          if (
            value > 0 &&
            config.getAcceptableEventPoints().includes(lowerKey)
          ) {
            agg.red_card = value;
          }

          return agg;
        },
        {}
      );

    return stack.saveScoreMove({
      ...competitor,
      aggregatedPoints: points
    });
  },

  /*
  Based on a score to display
  calculate corresponding column width
  @param {string} competitorTypeSections
  @param {string} competitorTypeSections
  @param {string} type - clockType from event.gameClock
  */
  calcScoreColumnWidth(competitor1: competitorTypeSections, competitor2: competitorTypeSections, type: string): void {
    const sectionScoreProvided = verifyIntegers(competitor1.sectionScore, competitor2.sectionScore);
    const scoreColWidthConfig = (type === 'sections' && sectionScoreProvided)
      ? config.getSectionsScoreColMinWidth()
      : config.getDefaultScoreColMinWidth();

    if (dynamicSettings.getScoreColWidth() < scoreColWidthConfig) {
      dynamicSettings.setScoreColWidth(scoreColWidthConfig);
    }

    const scoreToDisplay = `${competitor1.score}:${competitor1.score}`;
    const minRequiredLength = scoreToDisplay.length * 18;
    if (minRequiredLength > dynamicSettings.getScoreColWidth()) {
      dynamicSettings.setScoreColWidth(minRequiredLength);
    }
  },

  /*
  An event special bet to be showed up as second row of an event
  @param {specialBetType} specialBet - has name, type, and betMarkets which share signature of main betMarkets
  @return {specialEventType}
  */
  processEventSpecialBet(specialBet: specialBetType): specialEventType {
    const specialEventOdds = this.processEventBetMarketOdds(
      specialBet.betMarkets
    );

    return {
      id: specialBet.id,
      caption1: specialBet.captions.caption1.toLowerCase(),
      caption2: specialBet.captions.caption2.toLowerCase(),
      odds: specialEventOdds
    };
  },

  /*
  Evaluate every response header that meant to effect UI
  Restore dynamic settings from values rlt to previous pull
  Shift stack to the right
  @param {Record<string, string>} headers - HTTP response headers
  */
  refresh(headers: Record<string, string>) {
    // total pages
    headers["total-pages"] && dynamicSettings.setTotalPages(headers["total-pages"]);
    headers["time-millis-utc"] && dynamicSettings.setServerTime(headers["time-millis-utc"]);
    // remove previous pull score/odds changes
    dynamicSettings.flashValuesMovement();
    // remove calculated for previous pull score column width
    dynamicSettings.setScoreColWidth(0);
    // LIFO on stack
    stack.shiftStack();
  },

  /*
  Construct events ready to be consumed by React components
  @param {(BetMarketHeader | BetMarketEvent)[]} input - raw header or an event row
  @return {InfoScreenNormalized}
  */
  normalizer(
    input: (BetMarketHeader | BetMarketEvent)[]
  ): InfoScreenNormalized {
    const inputLen = input.length;
    const rows = [];

    for (let i = 0; i < inputLen; ++i) {
      if (!input[i]?.type) {
        continue;
      }

      if (input[i].type.toLowerCase() === "header") {
        const header = this.processHeader(input[i] as BetMarketHeader);
        rows.push(header);
        continue;
      }

      const eventRaw = input[i] as BetMarketEvent;
      const eventUI = this.processEvent(eventRaw);
      rows.push(eventUI);
    }

    return { rows };
  }
};

export default events;

import React from "react";
import "./IframeHeader.scss";

function TimelineHeader() {
  return (
    <div className={"iframe-header-box"}>
      <img className="header-icon timeline-icon" />
      <div className={"statistics-text"}>Liveticker</div>
    </div>
  );
}

export default TimelineHeader;
